import React, { useState, useEffect, useRef } from "react";
import SidebarRemoteSensing from "./sidebar/sidebar.view";
import OpenLayerView from "./map/open-layer.view";
import { dataFeaturesFake } from "../helper/helper";
import * as rsAction from "../../../redux/store/remote-sensing/remote-sensing.store";
import * as appActions from "../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from 'react-router-dom';
import { functionShowOverlayFromSidebar } from "./map/controller";

import "./map-remote-sensing.scss"

function MapRemoteSensing(props) {
    const { showLoading } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const mapRef = useRef(null);
    const overlayRef = useRef(null);

    const [showSidebar, setShowSidebar] = useState(true);
    const [data, setData] = useState([]);
    const [dataSelected, setDataSelected] = useState();
    const [pagination, setPagination] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);

    useEffect(() => {
        let params = {
            pageSize: queryParams.get('pageSize') || 10,
            pageIndex: queryParams.get('pageIndex') || 1,
            communeId: queryParams.get('communeId') || null,
            streetName: queryParams.get('streetName') || null,
        }
        GetListFeature(params);
        if(!queryParams.get('id')) setDataSelected(null)
    }, [location.search])

    useEffect(() => {
        if (queryParams.get('id')) {
            GetDetail(queryParams.get('id'))
        }
    }, [])

    const GetDetail = (id) => {
        showLoading(true);
        rsAction.GetDetailReflectTheScene(id).then(res => {
            if (res && res.content) {
                setDataSelected(res.content)
                showLoading(false)
            }
        }).catch(err => showLoading(false))
    }

    const refreshData = () => {
        let params = {
            pageSize: queryParams.get('pageSize') || 10,
            pageIndex: queryParams.get('pageIndex') || 1,
            communeId: queryParams.get('communeId') || null,
            streetName: queryParams.get('streetName') || null,
        }
        GetListFeature(params);
    }

    const GetListFeature = (params) => {
        showLoading(true);
        rsAction.GetListAll(params).then(res => {
            if (res && res.content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = res.content;
                setData(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }
    return (
        <div className="map_rs_container">
            <OpenLayerView
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                dataFeatures={data}
                featureSelected={dataSelected}
                mapRef={mapRef}
                overlayRef={overlayRef}
                refreshData={refreshData}
            />
            <SidebarRemoteSensing
                pagination={pagination}
                show={showSidebar}
                setShow={setShowSidebar}
                data={data}
                mapRef={mapRef}
                overlayRef={overlayRef}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MapRemoteSensing);