import React, { useEffect, useRef, useState } from "react";
import * as InitMapStore from "../../redux/store/init-map/init-map.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OpenlayerMapView from "../../components/open-layer/open-layer";
import functionButton from "../init-map-data/function-button/function-button";
import * as mapAction from "../../redux/store/project-management/gid.store"
import * as appActions from "../../core/app.store";
import CreatMapView from "./create-map/create-map";
import InitMapDataViewProject from "../init-map-data/init-map-data";
import "./project-management.scss"
//import ListProject from "./list-project/list-project.view"
const MapProjectManagement = (props) => {
    const { showLoading } = props;
    const planningId = 13;
    const mapId = 62;
    const refContent = useRef(null);
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        const header = document.getElementById("header");
        const footer = document.getElementById("footer");

        // Calculate the height
        const windowHeight = window.innerHeight;
        const headerHeight = header ? header.offsetHeight : 0;
        const footerHeight = footer ? footer.offsetHeight : 0;
        const contentHeight = windowHeight - headerHeight - footerHeight;

        console.log("headerHeight",headerHeight);
        console.log("footerHeight",footerHeight);

        // Set the height on refContent
        // -10px vì margin-bottom: 10px; của .map_project_container
        if (refContent.current) {
            refContent.current.style.height = `${contentHeight - 10}px`;
            setContainerHeight(contentHeight - 10)
        }
    })

    return (
        <div className="row map_project_container" ref={refContent}>
            <div className="col-12">
                <InitMapDataViewProject
                    mapId={mapId}
                    planningId={planningId}
                    isProject={false}
                    isMapProject
                    containerHeight={containerHeight}
                />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    mapSetting: state.initMap.mapSetting,
    settings: state.clientSetting.clientSetting,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MapProjectManagement);
