import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
// import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store"
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import * as helper from "../../helper/helper";
import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
//--- Mui
import { DropzoneArea } from 'material-ui-dropzone'
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    Tooltip,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AddCircleIcon from '@material-ui/icons/AddCircle';

import FormAddAddress from "./form-add-address.view";
import SuggestionAddress from "../suggestion-address/suggestion-address.view";
import RemoteSensingHistoryItem from "./form-remote-sensing-history.view";
import "./form-remote-sensing.scss";
import { convertAddress } from "../../helper/helper";

function FormRemoteSensing(props) {
    const { isOpen, onClose, isCreateNew, showLoading } = props;
    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const today = new Date();
    const fromDate = new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        0,
        0
    );
    const [isHot, setIsHot] = useState(true);
    const [openAddAddress, setOpenAddAddress] = useState(true);
    const [addressSelected, setAddressSelected] = useState();
    const [statusModel, setStatusModel] = useState([]);
    const [statusSelected, setStatusSelected] = useState();
    const [avatar, setAvatar] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [remoteSensingHistory, setRemoteSensingHistory] = useState([helper.rsHistoryItem]);

    useEffect(() => {
        // GetLookupStatus();
    }, []);

    // const GetLookupStatus = () => {
    //     showLoading(true);
    //     rsAction.GetLookupStatus().then(res => {
    //         if (res.content && res.content.length > 0) {
    //             setStatusModel(res.content)
    //             showLoading(false);
    //         } else setStatusModel([])
    //     }).catch(err => {
    //         err &&
    //             err.errorType &&
    //             ShowNotification(
    //                 viVN.Errors[err.errorType],
    //                 NotificationMessageType.Error
    //             );
    //         showLoading(false);
    //     })
    // }

    const handleChangeHot = (event) => {
        event.persist();
        setIsHot(event.target.checked);
    }

    const handleChangeAddress = (data) => {
        setAddressSelected(data);
    }
    useEffect(() => {
        console.log('addressSelected', addressSelected)
    }, [addressSelected])

    const onSubmit = data => {
        console.log('data', { ...data, addressSelected: addressSelected })
        if (addressSelected) {
            let formData = new FormData();
            data.title && formData.append('Title', data.title);
            data.description && formData.append('Content', data.description);
            formData.append('AddressId', addressSelected.id);
            if (avatar.length > 0) formData.append('image', avatar[0]);
            if (gallery.length > 0) {
                gallery.map(item => {
                    formData.append('galaryImages', item);
                })
            }
            showLoading(true)
            rsAction.CreateRoteSensing(formData).then(res => {
                res && ShowNotification("Thêm mới thành công", NotificationMessageType.Success);
                onClose();
                showLoading(false);
            }).catch(err => {
                showLoading(false);
                ShowNotification("Xảy ra lỗi vui lòng liên hệ admin", NotificationMessageType.Error);
            });
        }

    }

    const handleCreate = (data) => {

    }

    const handleAddRsHistory = () => {
        setRemoteSensingHistory([...remoteSensingHistory, {
            content: '',
            date: fromDate,
            files: []
        }]);
    }

    const handleRemoveRsHistory = (indexSelect) => {
        if (remoteSensingHistory.length > 1) {
            setRemoteSensingHistory(
                remoteSensingHistory.filter((item, index) => {
                    console.log(index, indexSelect)
                    if (index !== indexSelect) return item
                })
            );
        }
    }

    const handleChangeHistory = (indexSelect, data, type) => {
        console.log('data', data, type)
        let curr = remoteSensingHistory;
        curr[indexSelect][type] = data;
        setRemoteSensingHistory(curr);
        console.log('curr', curr)
    }

    const handleChangeFile = e => {
        console.log('e', e)
    }

    useEffect(() => { console.log('remoteSensingHistory', remoteSensingHistory) }, [remoteSensingHistory])
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">{isCreateNew ? 'Thêm mới' : 'Cập nhật'} biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {addressSelected && (
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <DialogContent className="pt-4 pb-2">

                            {/* --- title --- */}
                            <div className="form-group">
                                <label className="text-dark fw-6">
                                    Tiêu đề<span className="required"></span>
                                </label>
                                <TextField
                                    name="title"
                                    error={
                                        errors.title &&
                                        (errors.title.type === "required" ||
                                            errors.title.type === "maxLength")
                                    }
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    //   inputProps={{ maxLength: 200 }}
                                    inputRef={register({ required: true, maxLength: 150 })}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) =>
                                        setValue("title", e.target.value)
                                    }
                                />
                                {errors.title && errors.title.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.title && errors.title.type === "maxLength" && (
                                    <span className="error">Trường này không quá 150 ký tự</span>
                                )}
                            </div>

                            {/* --- description --- */}
                            <div className="form-group">
                                <label className="text-dark fw-6">Tóm tắt<span className="required"></span></label>
                                <textarea
                                    name="description"
                                    rows="5"
                                    ref={register({ required: true, maxLength: 500 })}
                                    maxLength="500"
                                    className={
                                        "form-control" +
                                        (errors.description && errors.description.type === "required"
                                            ? " is-invalid"
                                            : "")
                                    }
                                ></textarea>
                                {errors.description && errors.description.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.description && errors.description.type === "maxLength" && (
                                    <span className="error">Trường này không quá 500 ký tự</span>
                                )}

                            </div>

                            {/* --- status addAddress --- */}
                            <div className="form-group">
                                <div className="row">
                                    {statusModel && statusModel.length > 0 && (
                                        <div className="col-3">
                                            <label className="text-dark fw-6">
                                                Trạng thái<span className="required"></span>
                                            </label>

                                            <Autocomplete
                                                options={statusModel}
                                                getOptionLabel={(option) =>
                                                    typeof option === "string" ? option : option.name
                                                }
                                                value={statusSelected}
                                                onChange={(event, newValue) => {
                                                    setStatusSelected(newValue);
                                                    setValue("statusId", newValue.id);
                                                    console.log('newValue', newValue)
                                                }}
                                                disableClearable={true}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="statusId"
                                                        {...register('statusId', { required: true })}
                                                        error={
                                                            errors.statusId &&
                                                            errors.statusId.type === "required"
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                            {errors.statusId &&
                                                errors.statusId.type === "required" && (
                                                    <span className="error">Trường này là bắt buộc</span>
                                                )}
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <label className="text-dark fw-6">
                                            Địa chỉ<span className="required"></span>
                                        </label>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-9 col-lg-9">
                                                <TextField
                                                    fullWidth
                                                    inputRef={register({ required: true })}
                                                    type="text"
                                                    name="addressName"
                                                    value={convertAddress(addressSelected)}
                                                    error={
                                                        errors.addressName &&
                                                        errors.addressName.type === "required"
                                                    }
                                                    variant="outlined"
                                                    size="small"
                                                />
                                                {errors.addressName &&
                                                    errors.addressName.type === "required" && (
                                                        <span className="error">Trường này là bắt buộc</span>
                                                    )}
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <button className="btn_rs" onClick={() => setOpenAddAddress(true)}>+ Thêm địa chỉ</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark fw-6">
                                            Avatar<span className="required"></span>
                                        </label>
                                        <DropzoneArea
                                            filesLimit={1}
                                            acceptedFiles={['image/*']}
                                            maxFileSize={500000000}
                                            onChange={(files) => setAvatar(files)}
                                            showFileNames={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark fw-6">
                                            Thư viện ảnh<span className="required"></span>
                                        </label>
                                        <DropzoneArea
                                            filesLimit={99}
                                            acceptedFiles={['image/*']}
                                            maxFileSize={500000000}
                                            onChange={(files) => setGallery(files)}
                                            showFileNames={true}
                                        />
                                    </div>
                                </div>
                            </div>


                            {/* <div className="form-group">
                                <div className="row justify-content-center">
                                    <label className="text-dark fw-6 mt-3">
                                        Lịch sử biến động<span className="required"></span>
                                        <Tooltip title="Thêm mới" className="">
                                            <IconButton onClick={() => handleAddRsHistory()} >
                                                <AddCircleIcon color="primary" fontSize="medium" />
                                            </IconButton>
                                        </Tooltip>
                                    </label>
                                </div>
                                {remoteSensingHistory && remoteSensingHistory.map((item, index) => (
                                    <RemoteSensingHistoryItem
                                        data={item}
                                        index={index}
                                        action={handleRemoveRsHistory}
                                        handleChange={handleChangeHistory}
                                        hasRemove={remoteSensingHistory.length > 1}
                                    />
                                ))}
                            </div> */}

                            {/* --- history remote sensing --- */}


                            <DialogActions className="border-top">
                                <Button
                                    type="submit"
                                    onClick={onClose}
                                    variant="contained"
                                    startIcon={<CloseIcon />}
                                >
                                    Hủy
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                >
                                    Lưu
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </form>
                )}
            </Dialog>
            {openAddAddress &&
                <FormAddAddress
                    isOpen={openAddAddress}
                    onClose={() => setOpenAddAddress(false)}
                    onCloseAll={() => {onClose();setOpenAddAddress(false)}}
                    addressSelected={addressSelected}
                    setAddressSelected={setAddressSelected}
                />}
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FormRemoteSensing);