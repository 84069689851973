import React, { useEffect, useState } from "react";
import * as rsAction from "../../../redux/store/remote-sensing/remote-sensing.store";
import * as appActions from "../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TableItem from "./table-item/table-item.view";

import "./infomation-table.scss";

const InfoTableRemoteSensing = (props) => {
    const { showLoading } = props;

    const listTable = [
        {
            title: 'Tổng hợp biến động',
            isGeneral: true,
            isByCommune: false,
            isByDate: false,
            isByStatus: false,
        },
        {
            title: 'Biến động theo xã / phường',
            isGeneral: false,
            isByCommune: true,
            isByDate: false,
            isByStatus: false,
        },
        {
            title: 'Biến động theo thời gian',
            isGeneral: false,
            isByCommune: false,
            isByDate: true,
            isByStatus: false,
        },
        {
            title: 'Biến động theo trạng thái xử lý',
            isGeneral: false,
            isByCommune: false,
            isByDate: false,
            isByStatus: true,
        },
    ]

    return (
        <div>
            <div className="page_header">
                <div className="page_title">
                    <h3>Bảng biểu thông tin</h3>
                </div>
            </div>
            <div className="container mw_1500">
                {listTable.map((item, index) => (
                    <div key={index} className="mb-3">
                        <TableItem
                            index={index}
                            isGeneral={item.isGeneral}
                            isByCommune={item.isByCommune}
                            isByDate={item.isByDate}
                            isByStatus={item.isByStatus}
                            title={item.title}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(InfoTableRemoteSensing);