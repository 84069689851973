import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListAll = (data) => {
    const params = new URLSearchParams()

    params.append('PageIndex', data.pageIndex);
    params.append('PageSize', data.pageSize);
    data.sorting && params.append('Sorting', data.sorting);
    data.streetName && params.append('StreetName', data.streetName);
    data.communeId && params.append('CommuneId', data.communeId);
    data.fromDate && params.append('FromDate', data.fromDate);
    data.toDate && params.append('ToDate', data.toDate);
    data.search && params.append('Search', data.search);
    data.statusId && params.append('ReflectionStatusId', data.statusId);

    return service
        .get(ApiUrl.GetListReflectTheScene, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetDetailReflectTheScene = (id) => {
    return service
        .get(ApiUrl.GetDetailReflectTheScene(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
}

export const ExportFile = (data) => {
    const params = new URLSearchParams();

    params.append('PageIndex', data.pageIndex);
    params.append('PageSize', data.pageSize);
    data.sorting && params.append('Sorting', data.sorting);
    data.streetName && params.append('StreetName', data.streetName);
    data.communeId && params.append('CommuneId', data.communeId);
    data.fromDate && params.append('FromDate', data.fromDate);
    data.toDate && params.append('ToDate', data.toDate);
    data.search && params.append('Search', data.search);
    data.statusId && params.append('ReflectionStatusId', data.statusId);

    return service
        .postBinary(ApiUrl.ExportFile, null, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};


export const GetListHistory = (data) => {
    const params = new URLSearchParams()

    params.append('PageIndex', 1);
    params.append('PageSize', 10);
    data.reflectTheSceneId && params.append('reflectTheSceneId', data.reflectTheSceneId);

    return service
        .get(ApiUrl.GetListReflectTheSceneHistory, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const CreateRoteSensing = (body) => {
    return service.post(ApiUrl.CreateReflectTheScene, body).then(res => { return res }).catch(err => { throw err });
}

export const GetListPending = (data) => {
    const params = new URLSearchParams()

    params.append('PageIndex', data.pageIndex);
    params.append('PageSize', data.pageSize);
    data.sorting && params.append('Sorting', data.sorting);
    data.status && params.append('Status', data.status);
    return service
        .get(ApiUrl.GetListPending, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const DeleteReflectTheScene = (id) => {
    return service.post(ApiUrl.DeleteReflectTheScene(id)).then(res => { return res }).catch(err => { throw err });
}


export const DeletePending = (id) => {
    return service.post(ApiUrl.DeletePending(id)).then(res => { return res }).catch(err => { throw err });
}

export const ReceivePending = (body) => {
    return service.post(ApiUrl.ReceivePending, body).then(res => { return res }).catch(err => { throw err });
}

export const HandleReflect = (body) => {
    return service.post(ApiUrl.HandleReflect, body).then(res => { return res }).catch(err => { throw err });
}

export const AddShapeFile = (body) => {
    return service.post(ApiUrl.AddShapeFile, body).then(res => { return res }).catch(err => { throw err });
}

export const CreateHistory = (body) => {
    return service.post(ApiUrl.CreateHistory, body).then(res => { return res }).catch(err => { throw err });
}

export const CreatePending = (body) => {
    return service.post(ApiUrl.CreatePending, body).then(res => { return res }).catch(err => { throw err });
}

export const GetLookupUser = (unitId) => {
    const params = new URLSearchParams()

    unitId && params.append('unitId', unitId);
    return service
        .get(ApiUrl.GetLookupUser, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};