/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import RecordsManagement from  "./records-management.view.jsx"
function LandUsePLanningManagement() {
  return (
    <>
      <RecordsManagement
        planningKindId = {2}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LandUsePLanningManagement);
