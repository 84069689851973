import React, { useState } from "react";
import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction.js';
import { platformModifierKeyOnly } from 'ol/events/condition.js';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import TileWMS from 'ol/source/TileWMS';
import OSM from 'ol/source/OSM';
import Point from "ol/geom/Point";
import GeoJSON from "ol/format/GeoJSON";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import Zoom from 'ol/control/Zoom';
import XYZ from "ol/source/XYZ";
import * as config from "../../../../utils/configuration";
import * as apiConfig from "../../../../api/api-config";
import Jquery from "jquery";
import dateformat from "dateformat";
import { formatAddress } from '../../helper/helper';

export const functionHandleHover = (map, turnOn) => {
    if (map && turnOn) {
        map.on("pointermove", function (e) {
            functionHoverShowTooltip(map, map.getEventPixel(e.originalEvent))
            var hit = this.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                return true;
            });
            if (hit) {
                this.getTargetElement().style.cursor = 'pointer';
            } else {
                this.getTargetElement().style.cursor = '';
            }
        })
    }
}

export const functionClickShowOverLay = (map, overlay) => {
    map.on("singleclick", function (e) {
        functionShowOverlay(map, overlay, e);
    })
}

export const functionShowOverlayFromSidebar = (map, overlay, evt) => {
    map.getView().animate(
        {
            center: evt.coordinate,
            zoom: 17
        },
        { duration: 500 }
    );
    const myTimeout = setTimeout(() => functionShowOverlay(map, overlay, evt), 1000);
    // clearTimeout(myTimeout);
}


export const functionShowOverlay = (map, overlay, evt) => {
    const pixel = map.getPixelFromCoordinate(evt.coordinate);
    const overlayContent = document.getElementById("overlay-content");
    const overlayContent1 = document.getElementById("overlay-content1");
    let layer = null;
    let listLayer = null;
    if (map instanceof Map) {
        listLayer = map.getLayers();
    }
    listLayer.forEach((LayerObject) => {

        if ((LayerObject instanceof TileLayer) && (LayerObject.getClassName() === "qhc_sdd_tppleiku4326") && LayerObject.getVisible()) {
            console.log('map', LayerObject)
            layer = LayerObject
        }
    });
    const listTitleLayerVisible = [];
    const listVectorImageLayerVisible = [];
    const view = map.getView();
    const viewResolution = view.getResolution();
    let layerIndex = 0;

    const getFeaturesWithTitleLayer = (titleLayer) => {
        if(!titleLayer) return;
        console.log('titleLayer_xxx', titleLayer);
        const source = titleLayer.getSource();
        const layerId = source.getParams()['LayerId'];
        // const currentLayerSettingModel = this.state.listLayerSettingModel.filter(x => x.id === layerId)[0]
        const url = source.getFeatureInfoUrl(
            evt.coordinate,
            viewResolution,
            view.getProjection(),
            { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
        );
        if (url) {
            Jquery.ajax({
                type: "POST",
                url: url,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: (featureCollection) => {
                    //console.log("featureCollection", featureCollection)
                    if (featureCollection.features.length > 0) {
                        const _geojsonObject = featureCollection;
                        const infoObject = featureCollection.features[0];
                        const ListInfoMation = infoObject.properties;
                        overlay.setPosition(evt.coordinate)
                        overlayContent.style.display = 'block';
                        // overlayContent1.style.display = 'none';
                        overlayContent.innerHTML = `
                        <div class=""> 
                            <div class="overlay_header">
                                <p>Thông tin đối tượng</p>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Tên trường</th>
                                        <th>Giá trị</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Diện tích</td>
                                        <td>${ListInfoMation.dientich} m2</td>
                                    </tr>
                                    <tr>
                                        <td>Loại đất</td>
                                        <td>${ListInfoMation.loaidat}</td>
                                    </tr>
                                    <tr>
                                        <td>Phân loại</td>
                                        <td>${ListInfoMation.phanloai}</td>
                                    </tr>
                                    <tr>
                                        <td>Tên loại đất</td>
                                        <td>${ListInfoMation.ten_loai_dat}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        `
                        const buttonClose = document.getElementById("overlay_close");
                        buttonClose.addEventListener('click', () => {
                            overlayContent.style.display = 'none';
                            removeHiglightVectorLayer(map);
                        })
                        /*
                        * Lay thong tin de hien len bang thuoc tinh ben tay trai
                        * */

                        // const setDataForTabInformation = (featuresProperies) => {
                        //     if (currentLayerSettingModel) {
                        //         const layerSettingModel = currentLayerSettingModel;
                        //         const colsRaw = ConvertColsDataDetailViewToStandardData(
                        //             layerSettingModel.display_name.cols
                        //         );
                        //         const displayDetails = layerSettingModel.display_name.viewdetail;
                        //         //console.log('displayDetails',displayDetails);
                        //         if (displayDetails.use) {
                        //             displayDetails.cols.map((columnName) => {
                        //                 ListInfoMation.push({
                        //                     label: colsRaw[columnName],
                        //                     value: featuresProperies[columnName],
                        //                 });
                        //             });
                        //         }
                        //     } else if (this.state.relatedLayer) {
                        //         let relatedLayer = this.state.relatedLayer;
                        //         const colsRaw = {};
                        //         const displayDetails = []
                        //         //console.log('relatedLayer_YYYYYYYYYYYYYYYYYYyyy',relatedLayer);
                        //         if (Array.isArray(relatedLayer.display_name.cols) && relatedLayer.display_name.cols.length > 0) {
                        //             relatedLayer.display_name.cols.map((col) => {
                        //                 if (col.col != '__gid'
                        //                     && col.col != '____gid'
                        //                     && col.col != 'id'
                        //                     && col.col != 'ten_loai_dat') {
                        //                     displayDetails.push(col.col);
                        //                     colsRaw[col.col] = col.alias;
                        //                 }
                        //             })
                        //         }
                        //         if (Array.isArray(displayDetails) && displayDetails.length > 0) {
                        //             displayDetails.map((columnName) => {
                        //                 ListInfoMation.push({
                        //                     label: colsRaw[columnName],
                        //                     value: featuresProperies[columnName],
                        //                 });
                        //             });
                        //         }
                        //         //console.log('colsRaw_XXXXXXXXXXXXXXXXXXXXXXXXXXx',colsRaw);
                        //     }
                        // }
                        // n.features.map(features => MergeData(features.properties))
                        console.log('featureCollection__xxx', ListInfoMation);
                        // setDataForTabInformation(featureCollection.features[0].properties)
                        
                        /********************************************************************/
                        let _isHaveHighlightVectorlayer = false;
                        listLayer.forEach((_layer) => {
                            if ((_layer instanceof VectorLayer) && (_layer.getClassName() === "highlight-vectorlayer")) {
                                _isHaveHighlightVectorlayer = true;
                                const _newVectorSource = new VectorSource({
                                    features: new GeoJSON().readFeatures(_geojsonObject),
                                })
                                _layer.setSource(_newVectorSource)
                            }
                        })
                        if (!_isHaveHighlightVectorlayer) {
                            console.log('_geojsonObject', _geojsonObject)
                            listLayer.push(
                                new VectorLayer({
                                    className: "highlight-vectorlayer",
                                    source: new VectorSource({
                                        features: new GeoJSON().readFeatures(_geojsonObject),
                                    }),
                                    zIndex: 99,
                                    style: new Style({
                                        stroke: new Stroke({
                                            color: 'rgba(0,230,241,1)',
                                            width: 2,
                                        }),
                                        fill: new Fill({
                                            color: 'rgba(223,16, 188,.2)',
                                        }),
                                    }),
                                })
                            )
                        }
                    } else {
                        console.log('lỗi')
                        overlayContent.style.display = 'none';
                        overlayContent1.style.display = 'none';
                        removeHiglightVectorLayer(map);
                        // this.removeHiglightVectorLayer();
                        // layerIndex++;
                        // if (layerIndex < listTitleLayerVisible.length) {
                        //     getFeaturesWithTitleLayer(listTitleLayerVisible[layerIndex])
                        // } else {
                        //     this.props.SaveInfomationList([]);
                        // }
                    }
                },

            });
        }
    }

    getFeaturesWithTitleLayer(layer);

    // overlayContent.style.display = 'block'
    // overlay.setPosition(evt.coordinate)
    // overlayContent.innerHTML = `
    //         <div> 
    //             <img src='${apiConfig.api + data.filePath}' alt='${data.title}'/>
    //             <p>${data.title}</p>
    //         </div>
    //     `
    // overlayContent.innerHTML = `
    //         <div> 
    //             <h1>thuyên test</h1>
    //         </div>
    //     `
}

export const removeHiglightVectorLayer = (map) => {
    const _objectMap = map;
    if (!_objectMap) return;
    const _layers = _objectMap.getLayers();
    let _indexDelete = -1;
    _layers.getArray().map((_layer, _index) => {
        if ((_layer instanceof VectorLayer) && (_layer.getClassName() === "highlight-vectorlayer")) {
            _indexDelete = _index;
            return;
        }
    })
    if (_indexDelete != -1) _layers.removeAt(_indexDelete)
}

const functionHoverShowTooltip = (map, pixel) => {
    const tooltipContainer = document.getElementById(
        "tooltip-openlayer"
    );
    let left = pixel[0] + 20;
    let top = pixel[1] + 20;
    if (tooltipContainer) {
        tooltipContainer.style.left = left + "px";
        tooltipContainer.style.top = top + "px";
        tooltipContainer.style.opacity = "1";
    }

    const feature = map?.forEachFeatureAtPixel(pixel, function (
        feature
    ) {
        // console.log(feature)
        return feature;
    });
    if (
        feature &&
        feature.getProperties() &&
        feature.getProperties().name
    ) {
        tooltipContainer.innerHTML = `
            <div> 
                <p>${feature.getProperties().name}</p>
            </div>
        `
    } else {
        tooltipContainer.style.opacity = "0";
    }
}

export const zoomOut = (map) => {
    const view = map.getView();
    const zoom = view.getZoom();
    view.setZoom(zoom - 1);
}

export const zoomIn = (map) => {
    console.log('ok')
    const view = map.getView();
    const zoom = view.getZoom();
    view.setZoom(zoom - + 1);
}

