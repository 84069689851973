import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListAllDocument = (data) => {
    const params = new URLSearchParams()

    params.append('PageIndex', data.pageIndex);
    params.append('PageSize', data.pageSize);
    data.search && params.append('Search', data.search);
    return service
        .get(ApiUrl.GetListAllDocument, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListEventDate = (data) => {
    const params = new URLSearchParams()

    params.append('PageIndex', data.pageIndex);
    params.append('PageSize', data.pageSize);
    data.search && params.append('Search', data.search);
    return service
        .get(ApiUrl.GetListEventDate, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListDocumentByEventDate = (data) => {
    const params = new URLSearchParams()

    params.append('PageIndex', data.pageIndex);
    params.append('PageSize', data.pageSize);
    data.search && params.append('Search', data.search);
    return service
        .get(ApiUrl.GetListDocumentByEventDate, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListCommune = (data) => {
    const params = new URLSearchParams()

    params.append('PageIndex', data.pageIndex);
    params.append('PageSize', data.pageSize);
    data.search && params.append('Search', data.search);
    return service
        .get(ApiUrl.GetListCommune, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListDocumentByCommune = (data) => {
    const params = new URLSearchParams()

    params.append('PageIndex', data.pageIndex);
    params.append('PageSize', data.pageSize);
    data.search && params.append('Search', data.search);
    return service
        .get(ApiUrl.GetListDocumentByCommune, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};