import React from "react";
//--- Mui UI
import HistoryIcon from "@material-ui/icons/History";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import * as apiConfig from "../../../api/api-config";

function RenderImg(props) {
    const { data, key } = props;

    return (
        <div key={key} className="img_item">
            <img src={apiConfig.api + data} />
            <div className="img_item_overlay">
                <ZoomOutMapIcon className="" fontSize="large" />
                {/* <HistoryIcon className="" fontSize="large" /> */}
            </div>
        </div>
    )
}

export default RenderImg;