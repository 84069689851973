import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListNotifications = (userId, deviceId) => {
    const params = new URLSearchParams();
    params.append("User", userId);
    params.append("DeviceId", deviceId);
    return service.get(ApiUrl.GetListNotifications, params).then(res => { return res }).catch(err => { throw err });
}

export const ChangeReadStatus = (notificationId, userId, deviceId) => {
    const params = new URLSearchParams();
    params.append("NotificationId", notificationId);
    params.append("User", userId);
    params.append("DeviceId", deviceId);
    return service.post(ApiUrl.ChangeReadStatus, params).then(res => { return res }).catch(err => { throw err });
}

export const DeleteNotification = (id) => {
    return service.post(ApiUrl.DeleteNotification(id)).then(res => { return res }).catch(err => { throw err });
}

