/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
//--- Action
import * as placeTypeAction from "../../../redux/store/place-type/place-type.store"
//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { APIUrlDefault, NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import File_management from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditLandTypeManagement(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    landTypeId,
    GetListLandType,
    rowsPerPage,
    showLoading,
  } = props;
  const [placeTypeId, setPlaceTypeId] = useState();
  const [placeType, setPlaceType] = useState();

  const [isImageShow, setImageShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);

  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    showLoading(true);
    placeTypeAction
      .GetDetail(landTypeId)
      .then((res) => {
        if (res && res.content) {
          setPlaceType(res.content);
          setFiles(res.content.icon ? [res.content.icon] : []);
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    const landTypeModel = { ...data,
      id: landTypeId,
      placeTypeId: placeTypeId
    };
    files &&
    files.length > 0 &&
    files.forEach((file) => {
      if(file && file.fileId) {
        landTypeModel['DocumentUploadId'] = file.fileId;
      }
    });
    
    showLoading(true);
    placeTypeAction
      .Update(landTypeModel)
      .then((result) => {
        if (result) {
          GetListLandType(1, rowsPerPage);
          showLoading(false)
          onSuccess();
          ShowNotification(
            viVN.Success.EditLandType,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        showLoading(false)
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  const onOpenSelectFile = () => {
    setImageShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setImageShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setImageShow(false);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa loại địa điểm</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {placeType && (
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Tên loại địa điểm<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="name"
                    defaultValue={placeType.name}
                    className="w-100"
                    inputRef={register({ required: true })}
                    error={errors.name && errors.name.type === "required"}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Mã loại địa điểm<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="code"
                    defaultValue={placeType.code}
                    className="w-100"
                    inputRef={register({
                      required: true,
                      // pattern: /^([0-9]{6})$/g
                    })}
                    disabled={true}
                    error={errors.code && errors.code.type === "required"}
                  />
                  {errors.code && errors.code.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="text-dark">
                Icon<span className="required"></span>
              </label>
              {!isImageShow &&
                files &&
                files.length > 0 &&
                files.map((item) => (
                  <div key={item.fileName} style={{ width: "150px" }}>
                    <img
                      src={APIUrlDefault + item.filePreview}
                      alt={item.fileName}
                      title={item.fileName}
                      className="img-fluid mb-2"
                      style={{
                        width: "auto",
                        height: "auto",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </div>
                ))}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onOpenSelectFile}
                >
                  Chọn file
                </Button>
                <TextField
                  inputRef={register({ required: true })}
                  type="hidden"
                  name="image"
                  value={
                    (files && files.length > 0 && files[0].fileName) || ""
                  }
                />
                {errors.image && errors.image.type === "required" && (
                  <p className="error">Trường này là bắt buộc</p>
                )}
              </div>
            </div>

          </DialogContent>
          )}
          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {isImageShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isImageShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file - Chọn 1 ảnh</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <File_management
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif","svg"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
