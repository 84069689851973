import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import * as addressAction from "../../../../redux/store/address-management/address.store";
import * as communeAction from "../../../../redux/store/commune-management/commune.store"
import * as streetAction from "../../../../redux/store/street-management/street.store"
import OpenLayerView from "../../map-remote-sensing/map/open-layer.view";
import SuggestionAddress from "../suggestion-address/suggestion-address.view";

//--- Mui
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";

import "./form-remote-sensing.scss";

function MapView(props) {
    const { isOpen, onClose, onCloseAll, data } = props;
    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const mapRef = useRef(null);
    const overlayRef = useRef(null);

    return (
        <>
            <Dialog open={isOpen} onClose={onCloseAll ? onCloseAll : onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Xem biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onCloseAll ? onCloseAll : onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="pb-8">
                    <div className="row">
                        <div className="col-12" style={{ height: '500px' }}>
                            <OpenLayerView
                                pendingData={data}
                                overlayRef={overlayRef}
                                mapRef={mapRef}
                                isPending={true}
                            />
                        </div>

                    </div>

                </DialogContent>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MapView);