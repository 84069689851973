import React, { useState, useEffect, useRef } from "react";
import { Configs } from "../../../common/config";

import AddCircle from "@material-ui/icons/AddCircle";
import Select from "react-select";
import Pagination from '@material-ui/lab/Pagination';
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as appActions from "../../../core/app.store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as apiAction from "../../../redux/store/project-management/constructionInvestment.store.js";
import * as GidStore from "../../../redux/store/project-management/gid.store";
import * as ApiConfig from "../../../api/api-config";
import ClickOutside from "../../../components/click-outside/click-outside";
import {
  Button,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Divider,
} from "@material-ui/core";
import { Add, Create, Menu, PlusOne, Refresh } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import ControlFunctionOpenlayerType from "../../../components/open-layer/control-function-type/control-function-type";

import "./list-project.scss";
const ListProject = (props) => {
  const { showLoading,
    setCoordinate,
    setGid,
    setTableName,
    setIsOpenInfo,
    setProjectName,
    setListProject,
    listProject,
    page,
    setPage,
    totalItemCount,
    totalPage,
    handleChangePage,
    handleSelectSort,
    GetListAll,
    input, setInput,
    sort,
    setOpenCreateByShapeFile,
    setIsOpenCIForm
  } = props;
  const [data, setData] = useState();
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);
  const [projectSelect, setProjectSelect] = useState();
  const [isOpenSearch, setIsOpenSearch] = useState();
  const [options, setOptions] = useState('');
  const [typeId, setTypeId] = useState(0);
  const [type,setType] = useState();
  const [typeModel, setTypeModel] = useState();
  const [place, setPlace] = useState('');
  const [scale, setScale] = useState('');

  const pageSize = 10;

  const Options = [
    { value: 'modifiedDate desc', label: 'Mới nhất' },
    { value: 'modifiedDate asc', label: 'Cũ nhất' },
  ]
  const GetLookupType = () => {
    showLoading(true)
    apiAction.GetLookupType().then((res) => {
      if (res && res.content) {
        let data = res.content.map((item) => {
          return { ...item, label: item.name, value: item.id };
        })
        setOptions([{ label: 'Tất cả', value: 0 }, ...data])
        setTypeModel(res.content)
      }

      showLoading(false)
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    GetLookupType();
  }, [])
  
  const handleInputChange = (event) => {
    setInput(event.target.value)
  }

  const keyPress = (event) => {
    if (event.keyCode == 13) {
      GetListAll(1, 10, sort, input, place, scale);
      setIsOpenSearch(false)
    }
  }
  const handlePlaceChange = (event) => {
    setPlace(event.target.value)
  }
  const handleScaleChange = (event) => {
    setScale(event.target.value.replace(/[^0-9]/g, ''))
  }
  const refreshInput = () => {
    setInput('')
    setPlace('')
    setScale('')
    setTypeId(0)
    GetListAll(1, 10, sort);
    setIsOpenSearch(false)
  }
  const convertCoordinate = (data) => {
    console.log("convertCoordinateconvertCoordinate", data);
    if(!data) return;
    let arr = data.slice(11, data.indexOf(')')).split(' ')
    let coord = arr.map(i => parseFloat(i))
    let coorObject = { coordinate: coord }
    setCoordinate(coorObject)
    callClickMap(coorObject)
  }

  const callClickMap = (coorObject) => {
    props.controlOpenlayer(ControlFunctionOpenlayerType.ClickProjectItem, coorObject)
  }

  const handleSelectType = (data) => {
    console.log(data);
    setType(data);
    setTypeId(data.id);
    GetListAll(1, 10, sort, input, place, scale, data.id);
  }

  const containerRef = useRef(null);
  const functionButtonsRef = useRef(null);
  const listItemsRef = useRef(null);
  const paginationRef = useRef(null);

  useEffect(() => {
    if(!containerRef.current || !functionButtonsRef.current || !listItemsRef.current || !paginationRef.current) return;

    const containerHeight = containerRef.current.offsetHeight;
    const functionButtonsHeight = functionButtonsRef.current.offsetHeight;
    const paginationHeight = paginationRef.current.offsetHeight;
    
    const result = containerHeight - functionButtonsHeight- paginationHeight;
    console.log("result",result);
    listItemsRef.current.style.height = result + 'px';
  })

  return (
    <div className="container container_list" ref={containerRef}>
      {listProject && (
        <>
          <div className="align-items-center row" style={{minHeight: '70px'}} ref={functionButtonsRef}>
            <div className="col-3">
              <div className="length">
                <p>Có <span>{totalItemCount || 0}</span> kết quả</p>
              </div>
            </div>
            <div className="col-3 d-flex justify-content-center" style={{ position: 'relative' }}>
              <Tooltip title="Thêm mới">
                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={() => {
                    setIsOpenCIForm(true);
                    setOpenCreateByShapeFile(true);
                  }}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </div>
            <div className="col-3 d-flex justify-content-center" style={{ position: 'relative' }}>
              <Tooltip title="Tìm kiếm">
                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={() => setIsOpenSearch(!isOpenSearch)}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
              <ClickOutside show={isOpenSearch} onClickOutside={() => { setIsOpenSearch(false) }}>
                {() => (
                  <div className="left-map-search">
                    <Paper
                      component="form"
                      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    >
                      {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
                        <Menu />
                      </IconButton> */}
                      <InputBase
                        className="search_item"
                        sx={{ ml: 1, flex: 1 }}
                        onChange={handleInputChange}
                        value={input}
                        onKeyDown={keyPress}
                        placeholder="Nhập tên dự án"
                        inputProps={{ 'aria-label': 'search google maps' }}
                      />
                      <IconButton
                        type="button"
                        sx={{ p: '10px' }}
                        style={{ float: 'right' }}
                        aria-label="search"
                        onClick={() => refreshInput()}
                      >
                        <Refresh />
                      </IconButton>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <InputBase
                        className="search_item"
                        sx={{ ml: 1, flex: 1 }}
                        // style={{ marginLeft: '48px' }}
                        onChange={handlePlaceChange}
                        value={place}
                        onKeyDown={keyPress}
                        placeholder="Nhập khu vực"
                        inputProps={{ 'aria-label': 'search google maps' }}
                      />
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <InputBase
                        className="search_item"
                        sx={{ ml: 1, flex: 1 }}
                        // style={{ marginLeft: '48px' }}
                        onChange={handleScaleChange}
                        value={scale}
                        onKeyDown={keyPress}
                        placeholder="Nhập phạm vi dự án"
                        inputProps={{ 'aria-label': 'search google maps' }}
                      />
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: '#fff',
                            // marginLeft: '10px',
                          }),

                        }}
                        options={options}
                        value={type}
                        // Value={
                        //   options.filter((item) => {
                        //     if (typeId === item.value)
                        //       return item
                        //   })
                        // }
                        placeholder='Chọn loại dự án'
                        onChange={handleSelectType}
                      />
                    </Paper>
                  </div>
                )}
              </ClickOutside>
            </div>
            <div className="col-3 p-0">
              <div className="sort__">
                <Select
                  options={Options}
                  placeholder='Sắp xếp'
                  onChange={handleSelectSort}
                />
              </div>
            </div>
          </div>
          <div className="list_item" ref={listItemsRef}>
            {listProject.length > 0 ? (
              <>
                {listProject.map((item, index) => (
                  <div className="project_item">
                    <img
                      className="project_img"
                      src={ApiConfig.api + item.avatar?.filePreview}

                    />
                    <div
                      className="project_content"
                      onClick={() => {
                        setIsOpenInfo(true);
                        convertCoordinate(item.randomPoint);
                        setGid(item.gid);
                        setTableName(item.tableName);
                        setProjectName(item.name)
                      }}
                    >
                      <div className="">
                        <p className="project_title">
                          {item.name}
                        </p>
                        <p className="project_place">
                          Địa chỉ: {item.place}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <p>Không có thông tin dự án nào</p>
              </div>
            )}

          </div>
          <div className="pagination__" ref={paginationRef}>
            {totalItemCount && totalItemCount > 0 && (
              
                <Pagination
                  showFirstButton
                  showLastButton
                  page={page + 1}
                  count={totalPage}
                  siblingCount={1}
                  boundaryCount={1}
                  color='primary'
                  onChange={handleChangePage}
                />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  controlOpenlayer: state.openLayer.handleOutSideFunction,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListProject);