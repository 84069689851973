import avatar1 from "../map-remote-sensing/img/rs1.png";
import avatar2 from "../map-remote-sensing/img/rs2.png";

export const convertAddress = (address) => {
    if(address) {
        let result = address?.name + ", đường" +
        address?.streetName + ", " +
        address?.communeName;
    return result
    } else {
        return
    }
};

export const formatAddress = (address) => {
    let result = address?.fullAddressName + ", đường " +
        address?.streetName + ", " +
        address?.communeName;
    return result
};

const today = new Date();
const fromDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes(),
    0,
    0
);

export const rsHistoryItem = {
    content: '',
    date: fromDate,
    files: []
};

export function queryParamsToObject(url) {
    const params = new URLSearchParams(url);
    const paramsObject = {};
  
    for (const [key, value] of params) {
      // If the parameter key already exists in the object, create an array to store multiple values.
      if (paramsObject.hasOwnProperty(key)) {
        if (Array.isArray(paramsObject[key])) {
          paramsObject[key].push(value);
        } else {
          paramsObject[key] = [paramsObject[key], value];
        }
      } else {
        paramsObject[key] = value;
      }
    }
  
    return paramsObject;
  }  

export const dataFeaturesFake = [
    {
        id: 1,
        name: 'biến động 1',
        content: 'biến động 1 content',
        longtitude: '108.006680',
        latitude: '13.965816',
        statusId: 1,
        statusName: 'mới',
        avatar: avatar1,
    },
    {
        id: 2,
        name: 'biến động 2',
        content: 'biến động 2 content',
        longtitude: '108.031407',
        latitude: '13.965137',
        statusId: 2,
        statusName: 'đang xử lý',
        avatar: avatar2,
    }
]