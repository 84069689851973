import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import * as addressAction from "../../../../redux/store/address-management/address.store";
import * as communeAction from "../../../../redux/store/commune-management/commune.store"
import * as streetAction from "../../../../redux/store/street-management/street.store"
import OpenLayerView from "../../map-remote-sensing/map/open-layer.view";
import SuggestionAddress from "../suggestion-address/suggestion-address.view";

//--- Mui
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";

import "./form-remote-sensing.scss";

function FormAddAddress(props) {
    const { isOpen, onClose, onCloseAll, isCreateNew, addressSelected, setAddressSelected, submitAction, showLoading } = props;
    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const mapRef = useRef(null);
    const overlayRef = useRef(null);

    const [status, setStatus] = useState(true);
    const [isHot, setIsHot] = useState(true);
    const [isCreate, setIsCreate] = useState(true);
    const [name, setName] = useState('')
    const [communeModels, setCommuneModels] = useState([]);
    const [curCommune, setCurCommune] = useState();
    const [streetModels, setStreetModels] = useState([]);
    const [curStreet, setCurStreet] = useState();
    const [coord, setCoord] = useState([]);

    useEffect(() => {
        GetCommuneByDistrictId(36);
    }, []);

    useEffect(() => {
        console.log('coord', curCommune)
    }, [curCommune]);

    useEffect(() => {
        if (curCommune)
            GetStreetByCommuneId(curCommune.id);
    }, [curCommune]);

    const GetCommuneByDistrictId = (id) => {
        showLoading(true);
        communeAction
            .GetLookupCommune(id)
            .then((res) => {
                if (res && res.content) {
                    setCommuneModels(res.content);
                    showLoading(false);
                }
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };

    const GetStreetByCommuneId = (id) => {
        showLoading(true);
        streetAction
            .GetLookupStreet(id)
            .then((res) => {
                if (res && res.content) {
                    setStreetModels(res.content);
                    showLoading(false);
                }
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };

    const handleChangeHot = (event) => {
        event.persist();
        setIsHot(event.target.checked);
    }

    const onSubmit = (data) => {
        if (coord.length === 2 && data.address) {
            let formData = new FormData();
            formData.append('Name', data.address);
            formData.append('Longitude', coord[0]);
            formData.append('Latitude', coord[1]);
            formData.append('StreetId', data.streetId);

            // let params = {
            //     name: data.address,
            //     longitude: coord[0],
            //     latitude: coord[1],
            //     streetId: data.streetId
            // }
            showLoading(true);
            addressAction.CreateAddress(formData).then(res => {
                ShowNotification(
                    "Thêm địa chỉ mới thành công",
                    NotificationMessageType.Success
                );
                setAddressSelected({
                    value: res.content.id,
                    label: `${data.address}, ${curStreet.name}, ${curCommune.name}, tp. Pleiku, Gia Lai`,
                    id: res.content.id,
                    name: `${data.address}, ${curStreet.name}, ${curCommune.name}, tp. Pleiku, Gia Lai`,
                    // streetName: curStreet.name,
                    // communeName: curCommune.name
                })
                showLoading(false);
                onClose();
            }).catch(err => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false)
                onClose();
            })

        } else if (addressSelected) {
            onClose();
        } else {
            ShowNotification(
                "Vui lòng chọn địa điểm trên bản đồ",
                NotificationMessageType.Warning
            );
        }
    }

    const handleChangeAddress = (data) => {
        console.log('data', data)
        setAddressSelected(data);
        setCurCommune({ id: data.communeId, name: data.communeName });
        setCurStreet({ id: data.streetId, name: data.streetName });
        setName(data.name)
        setValue("communeId", data.communeName)
        setValue("streetId", data.streetName)
        setValue("address", data.name);
    }
    return (
        <>
            <Dialog open={isOpen} onClose={onCloseAll? onCloseAll : onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Chọn địa chỉ biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onCloseAll? onCloseAll: onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">
                        <div className="row pb-4">
                            {/* <div className="col-12">
                                <SuggestionAddress
                                    handleChoose={handleChangeAddress}
                                />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-6" style={{ height: '500px' }}>
                                <OpenLayerView
                                    onClickDefault={true}
                                    setCoord={setCoord}
                                    overlayRef={overlayRef}
                                    mapRef={mapRef}
                                    isAddress={true}
                                />
                            </div>
                            {/* {!addressSelected && ( */}
                            {true && (
                                <div className="col-6">
                                    <div className="form-group">
                                        <div className="row">
                                            {communeModels && communeModels.length > 0 && (
                                                <div className="col-12">
                                                    <label className="text-dark">
                                                        Xã / Phường<span className="required"></span>
                                                    </label>

                                                    <Autocomplete
                                                        options={communeModels}
                                                        getOptionLabel={(option) =>
                                                            typeof option === "string" ? option : option.name
                                                        }
                                                        value={curCommune}
                                                        onChange={(event, newValue) => {
                                                            setCurCommune(newValue);
                                                            setValue("communeId", newValue.id);
                                                            console.log('newValue', newValue)
                                                        }}
                                                        disableClearable={true}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                name="communeId"
                                                                {...register('communeId', { required: true })}
                                                                error={
                                                                    errors.communeId &&
                                                                    errors.communeId.type === "required"
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        )}
                                                    />
                                                    {errors.communeId &&
                                                        errors.communeId.type === "required" && (
                                                            <span className="error">Trường này là bắt buộc</span>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            {streetModels && streetModels.length > 0 && (
                                                <div className="col-12">
                                                    <label className="text-dark">
                                                        Đường <span className="required"></span>
                                                    </label>

                                                    <Autocomplete
                                                        options={streetModels}
                                                        getOptionLabel={(option) =>
                                                            typeof option === "string" ? option : option.name
                                                        }
                                                        value={curStreet}
                                                        onChange={(event, newValue) => {
                                                            setCurStreet(newValue);
                                                            setValue("streetId", newValue.id)

                                                        }}
                                                        disableClearable={true}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                name="streetId"
                                                                {...register('streetId', { required: true })}
                                                                error={
                                                                    errors.streetId &&
                                                                    errors.streetId.type === "required"
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        )}
                                                    />
                                                    {errors.streetId &&
                                                        errors.streetId.type === "required" && (
                                                            <span className="error">Trường này là bắt buộc</span>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            {curStreet && (
                                                <div className="col-12">
                                                    <label className="text-dark">
                                                        Địa chỉ số nhà<span className="required"></span>
                                                    </label>

                                                    <TextField
                                                        fullWidth
                                                        inputRef={register({ required: true })}
                                                        type="text"
                                                        name="address"
                                                        // value={name}
                                                        // onChange={
                                                        //     e => handleChangeName(e)
                                                        // }
                                                        error={
                                                            errors.address &&
                                                            errors.address.type === "required"
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                    {errors.address &&
                                                        errors.address.type === "required" && (
                                                            <span className="error">Trường này là bắt buộc</span>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <DialogActions className="border-top">
                            <Button
                                type="submit"
                                onClick={onClose}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                            >
                                Lưu
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FormAddAddress);