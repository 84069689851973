import React, { useState, useRef } from "react";
import { listBaseMap } from "../../components/model";

//--- Mui ui
import { IconButton, Tooltip } from "@material-ui/core";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RepeatIcon from '@material-ui/icons/Repeat';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LayersIcon from '@material-ui/icons/Layers';
import MapIcon from '@material-ui/icons/Map';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import history from "../../../../common/history";
import ClickOutside from "../../../../components/click-outside/click-outside";
import { baseLayer } from "../map/open-layer.view";

import "./map.scss";

function MapController(props) {
    const {
        isPending,
        addAddress,
        handleZoomIn,
        handleZoomOut,
        handleViewFullScreen,
        handleReset,
        changeBaseMap,
        viewCurrentLocaltion,
        toggleDisplayLayer,
    } = props;

    const [defaultLayer, setDefaultLayer] = useState(baseLayer)
    const [baseMap, setBaseMap] = useState(false);
    const [openLayerOptions, setOpenLayerOptions] = useState(false);
    const [openMapOptions, setOpenMapOptions] = useState(false);

    const handleChangeBaseMap = () => {
        if (baseMap) {
            changeBaseMap(listBaseMap[1].url);
        } else {
            changeBaseMap(listBaseMap[0].url);
        }
        setBaseMap(!baseMap);

    }

    const handleResetMap = () => {
        handleReset();
        history.push('/ban-do-vien-tham')
    }

    const listTools = [
        {
            name: "zoomIn",
            title: "Phóng to",
            icon: ZoomInIcon,
            action: handleZoomIn,
            hidden: false,
        },
        {
            name: "zoomOut",
            title: "Thu nhỏ",
            icon: ZoomOutIcon,
            action: handleZoomOut,
            hidden: false,
        },
        {
            name: "reset",
            title: "Reset",
            icon: RepeatIcon,
            action: handleResetMap,
            hidden: isPending,
        },
        {
            name: "location",
            title: "Vị trí hiện tại",
            icon: LocationOnIcon,
            action: viewCurrentLocaltion,
            hidden: false,
        },
        {
            name: "layer",
            title: "Lớp layer",
            icon: LayersIcon,
            action: () => handleClickOutside(true),
            hidden: false,
            hasOption: true,
        }, {
            name: "baseMap",
            title: "Bản đồ nền",
            icon: MapIcon,
            action: handleChangeBaseMap,
            hidden: false,
            hasOption: false,
        },
        {
            name: "fullScreen",
            title: "Toàn màn hình",
            icon: ZoomOutMapIcon,
            action: handleViewFullScreen,
            hidden: addAddress,
        },
    ]

    const handleClickOutside = (isbutton) => {
        if (isbutton) {
            setOpenLayerOptions(!openLayerOptions)
            // console.log('outside')
        } else {
            setOpenLayerOptions(false)
        }
    };

    const handletoggleDisplayLayer = (layer, isCheck) => {
        const listLayer = [];
        defaultLayer.map((item, index) => {
            if (item.table == layer.table) {
                listLayer.push({ ...item, is_check: isCheck })
            } else {
                listLayer.push({ ...item })
            }
            // objLayer = listLayer[index]
        })
        setDefaultLayer(listLayer)
        toggleDisplayLayer(layer, isCheck);
    }

    return (
        <div className="map_controller_container">
            {listTools.map((item, index) => (
                <div ref={item.ref} key={index} id={item.name} className="tool_item" hidden={item.hidden}>
                    <Tooltip title={item.title} onClick={item.action}>
                        <IconButton>
                            <item.icon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    {item.hasOption && (
                        <ClickOutside show={openLayerOptions} refWrapper={item.ref} onClickOutside={() => handleClickOutside()}>
                            {() => (
                                <div className="tool_option">
                                    {defaultLayer.map((item) => (
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                defaultChecked={item.is_check}
                                                type="checkbox"
                                                id={`flexCheck${item.table}`}
                                                onChange={e => handletoggleDisplayLayer(item, e.target.checked)}
                                            />
                                            <label class="form-check-label" for={`flexCheck${item.table}`}>
                                                {item.name}
                                            </label>
                                        </div>
                                    ))}

                                </div>
                            )}
                        </ClickOutside>
                    )}
                </div>
            ))}
        </div>
    )
}

export default MapController;