import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
//--- Mui
import { DropzoneArea } from 'material-ui-dropzone'
import {
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";


function HandleReflect(props) {
    const { isOpen, onClose, isCreateNew, showLoading, pendingId, reloadData } = props;
    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const [gallery, setGallery] = useState([]);

    const onSubmit = data => {
        // console.log('data', { ...data, addressSelected: addressSelected })
        if (gallery.length > 0) {
            let formData = new FormData();
            data.description && formData.append('Content', data.description);
            formData.append('id', pendingId);
            if (gallery.length > 0) {
                gallery.map(item => {
                    formData.append('image', item);
                })
            }
            showLoading(true)
            rsAction.HandleReflect(formData).then(res => {
                res && ShowNotification("Cập nhật thành công", NotificationMessageType.Success);
                onClose();
                showLoading(false);
                reloadData();
            }).catch(err => {
                showLoading(false);
                ShowNotification("Xảy ra lỗi vui lòng liên hệ admin", NotificationMessageType.Error);
            });
        } else {
            ShowNotification("Vui lòng thêm ảnh biến động", NotificationMessageType.Error, 'center');

        }
    }
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">{isCreateNew ? 'Thêm mới' : 'Cập nhật'} biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">

                        {/* --- description --- */}
                        <div className="form-group">
                            <label className="text-dark fw-6">Nội dung biến động<span className="required"></span></label>
                            <textarea
                                name="description"
                                rows="5"
                                ref={register({ required: true, maxLength: 500 })}
                                maxLength="500"
                                className={
                                    "form-control" +
                                    (errors.description && errors.description.type === "required"
                                        ? " is-invalid"
                                        : "")
                                }
                            ></textarea>
                            {errors.description && errors.description.type === "required" && (
                                <span className="error">Trường này là bắt buộc</span>
                            )}
                            {errors.description && errors.description.type === "maxLength" && (
                                <span className="error">Trường này không quá 500 ký tự</span>
                            )}

                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-12">
                                    <label className="text-dark fw-6">
                                        Ảnh biến động<span className="required"></span>
                                    </label>
                                    <DropzoneArea
                                        filesLimit={99}
                                        acceptedFiles={['image/*']}
                                        maxFileSize={50000000000}
                                        onChange={(files) => setGallery(files)}
                                        showFileNames={true}
                                    />
                                </div>
                            </div>
                        </div>



                        <DialogActions className="border-top">
                            <Button
                                type="submit"
                                onClick={onClose}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                            >
                                Lưu
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HandleReflect);