import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListStreet = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    name && params.append("keyword", name.trim());
    return service.get("/api/admin/Street/GetList", params).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetailStreet = (id) => {
    return service.get(ApiUrl.GetDetailStreet(id)).then(res => { return res }).catch(err => { throw err });
}
export const GetLookupStreet = (streetId) => {
    const params = new URLSearchParams();
    params.append('communeId', streetId)
    return service.get(ApiUrl.GetLookupStreet.trim(), params).then(res => { return res }).catch(err => { throw err });
}

export const CreateStreet = (body) => {
    return service.post(ApiUrl.CreateStreet, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateStreet = (body) => {
    return service.post(ApiUrl.UpdateStreet, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteStreet = (id) => {
    return service.postParams(ApiUrl.DeleteStreet(id)).then(res => { return res }).catch(err => { throw err });
}