import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import { useLocation } from "react-router-dom";
import { TextField, Fab, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RefreshIcon from "@material-ui/icons/Refresh";
import exportExcelIcon from "../../../../assets/icon/excel.svg";
import * as communeAction from "../../../../redux/store/commune-management/commune.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import history from "../../../../common/history";
import dateformat from "dateformat";
import { DomainAdminSide } from "../../../../utils/configuration";

const formatDateParams = (input) => {
    const [day, month, year] = input.split('-');
    const formattedDate = new Date(`${year}-${month}-${day}T00:00:00Z`)

    const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
        timeZone: 'Asia/Bangkok', // Chọn múi giờ cho Đông Dương
    };
    return formattedDate.toLocaleString('en-US', options);
}

const FilterReflect = (props) => {
    const { showLoading, exportAction } = props;
    const location = useLocation();
    const url = location.pathname;

    const queryParams = new URLSearchParams(location.search);
    const date = new Date();
    const today = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        0,
        0
    );
    const fromDateDefault = queryParams.get('fromDate') ?
        formatDateParams(queryParams.get('fromDate')) :
        today;
    const toDateDefault = queryParams.get('toDate') ?
        formatDateParams(queryParams.get('toDate')) :
        today;
    const defaultStatusValue = queryParams.get("status") === 'false' ? 1 : queryParams.get("status") === 'true' ? 2 : 0;
    const [communeModels, setCommuneModels] = useState([]);
    const [curCommune, setCurCommune] = useState({ id: 0, name: 'Chọn xã - phường' });
    const [fromDate, setFromDate] = useState(fromDateDefault);
    const [toDate, setToDate] = useState(toDateDefault);
    const [validationError, setValidationError] = useState('');

    useEffect(() => {
        GetCommuneByDistrictId(36);
    }, []);

    const GetCommuneByDistrictId = (id) => {
        showLoading(true);
        communeAction
            .GetLookupCommune(id)
            .then((res) => {
                if (res && res.content) {
                    setCommuneModels(res.content);
                    if (queryParams.get('communeId')) {
                        res.content.find(item => {
                            if (item.id === parseInt(queryParams.get('communeId'))) {
                                setCurCommune(item)
                            }
                        })
                    }
                    showLoading(false);
                }
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };
    const handleFromDateChange = (date) => {
        setFromDate(date)
        console.log('todate', toDate)
        if (isDateRangeValid(date, toDate)) {
            queryParams.set('fromDate', dateformat(date, "dd-mm-yyyy"));
            queryParams.set('toDate', dateformat(toDate, "dd-mm-yyyy"));
            pushParams();
        }
    };

    const handleToDateChange = (date) => {
        setToDate(date)
        if (isDateRangeValid(fromDate, date)) {
            queryParams.set('fromDate', dateformat(fromDate, "dd-mm-yyyy"));
            queryParams.set('toDate', dateformat(date, "dd-mm-yyyy"));
            pushParams();
        }
    };

    const handleChangeName = (event) => {
        if (event.key === "Enter") {
            const value = event.target.value.trim();
            queryParams.set('search', value);
            pushParams();
        }
    }

    const handleChangeStreet = (event) => {
        if (event.key === "Enter") {
            const value = event.target.value.trim();
            queryParams.set('streetName', value);
            pushParams();
        }
    }

    const handleChangeCommune = (data) => {
        setCurCommune(data)
        console.log(data)
        queryParams.set('communeId', data.id);
        pushParams();
    }

    const handleChangeStatus = (value) => {
        const status = parseInt(value);
        switch (status) {
            case 0:
                queryParams.delete('statusId')
                break;
            case 2:
                queryParams.set('statusId', 2)
                break;
            case 3:
                queryParams.set('statusId', 3)
                break;
            default:
                queryParams.delete('statusId')
        }
        pushParams();
    }

    const isDateRangeValid = (startDate, endDate) => {
        // console.log('fromdate', startDate)
        // console.log('todate', endDate)

        if (startDate && endDate && startDate > endDate) {
            setValidationError('Ngày bắt đầu phải nhỏ hơn hoặc bằng ngày kết thúc');
            return false
        } else {
            setValidationError('');
            return true
        }
    }

    const pushParams = () => {
        history.push({
            search: queryParams.toString(),
        })
    }

    const handleRefresh = () => {
        window.location.replace(DomainAdminSide + url)
        // history.push({
        //     pathname: url
        // })
    }

    return (
        <div className="row">
            <div className="col-md-10">
                <div className="row">
                    <div className="col-md-2">
                        <div className="filter_item">
                            <TextField
                                fullWidth
                                id="standard-basic"
                                label="Từ khóa"
                                defaultValue={queryParams.get('search')}
                                type="search"
                                variant="standard"
                                InputProps={{ maxLength: 200 }}
                                onKeyPress={handleChangeName}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="filter_item">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="Từ ngày"
                                    id="fromDate"
                                    name="fromDate"
                                    onChange={(date) => date && handleFromDateChange(date)}
                                    value={fromDate}
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    error={Boolean(validationError)}
                                    helperText={validationError}
                                    showTodayButton={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            fullWidth
                                            margin="normal"
                                            required
                                            InputProps={{
                                                endAdornment: <DateRangeIcon />,
                                            }}
                                        />
                                    )}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="filter_item">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="Đến ngày"
                                    id="toDate"
                                    name="toDate"
                                    onChange={(date) => date && handleToDateChange(date)}
                                    value={toDate}
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    error={Boolean(validationError)}
                                    helperText={validationError}
                                    showTodayButton={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            fullWidth
                                            margin="normal"
                                            required
                                            InputProps={{
                                                endAdornment: <DateRangeIcon />,
                                            }}
                                        />
                                    )}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="filter_item">
                            {communeModels && communeModels.length > 0 && (
                                <Autocomplete
                                    options={communeModels}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={curCommune}
                                    onChange={(event, newValue) => {
                                        handleChangeCommune(newValue);
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="communeId"
                                            variant="standard"
                                            size="small"
                                        />
                                    )}
                                />
                            )}
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="filter_item">
                            <TextField
                                fullWidth
                                id="standard-basic"
                                label="Tên đường"
                                defaultValue={queryParams.get('streetName')}
                                type="search"
                                variant="standard"
                                InputProps={{ maxLength: 200 }}
                                onKeyPress={handleChangeStreet}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="filter_item">
                            <select
                                class="form-select"
                                aria-label="Default select example"
                                onChange={e => handleChangeStatus(e.target.value)}
                                defaultValue={defaultStatusValue}
                            >
                                <option selected value={0}>---Trạng thái---</option>
                                <option value={3}>Đã xử lý</option>
                                <option value={2}>Đang xử lý</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-2">
                <div className="export_">
                    <Tooltip title="Làm mới">
                        <Fab
                            color="primary"
                            aria-label="filter"
                            size="small"
                            onClick={handleRefresh}
                            className="mt-2 mr-3"
                        >
                            <RefreshIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Export">
                        <img
                            src={exportExcelIcon}
                            alt="Export"
                            className="mt-2 mr-3 cursor-pointer"
                            onClick={exportAction}
                            style={{
                                width: 26,
                                height: 26,
                            }}
                        />
                    </Tooltip>
                </div>
            </div>


        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FilterReflect);