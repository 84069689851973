import React, { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
// import ListResult from "./list-result/list-result.view";
// import DetailResult from "./detail-result/detail-result.view";
import SearchRemoteSensing from "../components/search/searchRemoteSensing.view";
import * as rsAction from "../../../redux/store/remote-sensing/remote-sensing.store";
import * as appActions from "../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from 'react-router-dom';

// import "./history-remote-sensing.scss";

function RemoteSensingManagement(props) {
    const { showLoading } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [data, setData] = useState([]);
    const [dataHistory, setDataHistory] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [communeIdSelected, setCommuneIdSelected] = useState(queryParams.get('communeId'));
    const [streetNameSelected, setStreetNameSelected] = useState(queryParams.get('streetName'));
    const [remoteSensingSelected, setRemoteSensingSelected] = useState();

    const GetList = (params) => {
        showLoading(true);
        rsAction.GetListAll(params).then(res => {
            if (res && res.content) {
                setData(res.content.items)
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }

    const GetListHistory = (params) => {
        showLoading(true);
        rsAction.GetListHistory(params).then(res => {
            if (res && res.content) {
                setDataHistory(res.content.items)
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }

    useEffect(() => {
        console.log('ok')
        let params = {
            pageSize: pageSize,
            pageIndex: pageIndex,
            communeId: communeIdSelected || null,
            streetName: streetNameSelected || null,
        }
        GetList(params);
    }, [JSON.stringify(queryParams), queryParams.get('communeId')])

    useEffect(() => {
        if (remoteSensingSelected) {
            let params = {
                pageSize: pageSize,
                pageIndex: pageIndex,
                reflectTheSceneId: remoteSensingSelected.id,
            }
            GetListHistory(params);
        }
    }, [remoteSensingSelected])

    return (
        <div>
            <div className="page_title">
                <h3>Quản lý biến động</h3>
            </div>
            <div className="container mw_1500 history_rs">
                <div className="row gap_20">
                    <div className="list_block">
                        <div className="search_block">
                            <SearchRemoteSensing />
                        </div>
                        <div className="search_result">
                            <div className="name_result">
                                <p>Kết quả tìm kiếm</p>
                                <h4>Phường Thắng Lợi, thành phố Pleiku</h4>
                            </div>
                            <div className="title_">
                                Biến động
                            </div>
                            <div className="list_rs">
                                {/* <ListResult
                                    data={data}
                                    remoteSensingSelected={remoteSensingSelected}
                                    setRemoteSensingSelected={setRemoteSensingSelected}
                                /> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="list_detail_block">
                        <DetailResult
                            itemSelected={remoteSensingSelected}
                            dataHistory={dataHistory}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RemoteSensingManagement);