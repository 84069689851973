import React, { useState } from "react";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as placeKindAction from "../../../redux/store/place-kind/place-kind.store"
//--- Action
import Autocomplete from "@material-ui/lab/Autocomplete";
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddLandTypeManagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListLandType,
    rowsPerPage,
    showLoading,
    placeTypeModels
  } = props;
  const [placeTypeId, setPlaceTypeId] = useState();
  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    const landTypeModel = { ...data,
      placeTypeId: placeTypeId }
    showLoading(true);

    placeKindAction.Create(landTypeModel).then((result) => {
      if (result && result.content && result.content.status === true) {
        GetListLandType(1, rowsPerPage);
        showLoading(false)
        onSuccess();
        ShowNotification(
          viVN.Success.AddLandType,
          NotificationMessageType.Success
        )
      }
    }).catch((err) => {
      showLoading(false)
      onSuccess();
      ShowNotification(
        viVN.Errors[err.errorType],
        NotificationMessageType.Error
      )
    })
  };


  const handleOnchangePlaceType = (event, newValue) => {
    if (newValue) {
      setPlaceTypeId(newValue.id);
    }
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm chi tiết loại địa điểm</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Tên chi tiết loại địa điểm<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="name"
                    className="w-100"
                    inputRef={register({ required: true })}
                    error={errors.name && errors.name.type === "required"}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Mã chi tiết loại địa điểm<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="code"
                    className="w-100"
                    inputRef={register({
                      required: true,
                    })}
                    error={errors.code && errors.code.type === "required"}
                  />
                  {errors.code && errors.code.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    {placeTypeModels && placeTypeModels.length > 0 && (
                  <Autocomplete
                    id="placeType"
                    options={placeTypeModels}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    onChange={(event, newValue) =>
                      handleOnchangePlaceType(event, newValue)
                    }
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Chọn loại địa điểm (*)"
                        name={`placeTypeId`}
                        inputRef={register({ required: true })}
                        size="small"
                        variant="outlined"
                        error={
                          errors[`placeTypeId`] &&
                          errors[`placeTypeId`].type === "required"
                        }
                      />
                    )}
                  />
                )}
                  </div>
              </div>
            </div>

          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
