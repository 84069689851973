import React, { useEffect, useRef, useState } from "react";
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as InvestorAction from "../../../../redux/store/investor/investor.store";
import * as PlanningAction from "../../../../redux/store/planning/planning.store";
import * as ConstructionInvestmentAction from "../../../../redux/store/project-management/constructionInvestment.store";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import FileManagement from "../../../../components/file_management/file_management";
import {
    NotificationMessageType,
    changeAlias,
    APIUrlDefault,
} from "../../../../utils/configuration";
import NotificationService from "../../../../common/notification-service";
import ShowNotification from "../../../../components/react-notifications/react-notifications";

function ConstructionInvestmentForm(props) {
    const { isOpen, setIsOpen, dataItems, GetListProject, setIsOpenCIDetail, GetListAll, openCreateByShapeFile } = props;
    const classes = useStyles();

    const [name, setName] = useState();
    const [type, setType] = useState("");
    const [planningDetailId, setPlanningDetailId] = useState();
    const [status, setStatus] = useState("");
    const [investorModel, setInvestorModel] = useState([]);
    const [planningModel, setPlanningModel] = useState([]);
    const [planningSelect, setPlanningSelect] = useState("");
    const [investorSelect, setInvestorSelect] = useState("");
    const [files, setFiles] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);
    const [isShow, setShow] = useState(false);
    const [fileSelected, setFileSelected] = useState([]);
    const [isDocumentShow, setDocumentShow] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [documentsTemp, setDocumentsTemp] = useState([]);
    const [statusModel, setStatusModel] = useState();
    const [typeModel, setTypeModel] = useState();
    const [gallerySelected, setGallerySelected] = useState([]);
    const [isGalleryShow, setGalleryShow] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [galleryTemp, setGalleryTemp] = useState([]);

    const { register, handleSubmit, errors, setValue, clearErrors, setError } =
        useForm({
            mode: "all",
            reValidateMode: "onBlur",
        });
    useEffect(() => {
        console.log('errorserrorserrorserrors', errors)
    }, [errors])
    const clearInfo = () => {
        setGallerySelected([]);
        setGalleryTemp([]);
        setGallery([]);
        setFiles([]);
        setFilesTemp([]);
        setDocuments([]);
        setDocumentsTemp([]);
        setFileSelected([]);
        setType('');
        setStatus('');
        setInvestorSelect('');
        setShapeFile(null);
    }
    const onSubmit = (data) => {
        if (!data) {
            return;
        } else {
            console.log(data);
            let formData = new FormData;
            formData.append('Name', data?.name)
            formData.append('Investment', data?.Investment)
            formData.append('Scale', data?.Scale)
            formData.append('Note', data?.note)
            formData.append('Place', data?.Place)
            formData.append('TypeId', data?.typeId)
            formData.append('ConstructionInvestmentStatusId', data?.status)
            formData.append('PlanningDetailId', data?.planningDetailId || 0)

            formData.append('InvestorId', data?.investor)
            formData.append('Order', data?.order)
            formData.append('DocumentUploadId', files[0].fileId)

            if(!openCreateByShapeFile) {
                formData.append('TableName', dataItems.table)
                formData.append('Gid', dataItems.gid)
                formData.append('Code', `${dataItems.table}.${dataItems.gid}`)
            } else {
                formData.append('shpFile', shapeFile)
            }

            documents.length > 0 && documents.map(item => {
                formData.append('Documents', item.fileId)
            })
            gallery.length > 0 && gallery.map(item => {
                formData.append('Gallerys', item.fileId)
            })
            ConstructionInvestmentAction.CreateConstructionInvestment(formData, openCreateByShapeFile).then(res => {
                GetListAll(1, 10)
                setIsOpen(false);
                if(!openCreateByShapeFile) {
                    GetListProject(dataItems.gid, dataItems.table);
                }
                setIsOpenCIDetail(false);
                NotificationService.success("Thêm mới thành công");
                clearInfo();
            })
        }
    }
    const GetLookupStatus = () => {
        ConstructionInvestmentAction.GetLookupStatus().then((res) => {
            setStatusModel(res && res.content ? res.content : [])
        }).catch(err => console.log(err))
    }

    const GetLookupType = () => {
        ConstructionInvestmentAction.GetLookupType().then((res) => {
            setTypeModel(res && res.content ? res.content : [])
        }).catch(err => console.log(err))
    }
    const GetLookupInvestor = () => {
        InvestorAction.GetLookUpInvestor().then((res) => {
            setInvestorModel(res && res.content ? res.content : [])
        }).catch(err => console.log(err))
    }
    const GetLookupPlanning = () => {
        PlanningAction.GetLookUpPlanning().then((res) => {
            setPlanningModel(res && res.content ? res.content : [])
        }).catch(err => console.log(err))
    }
    useEffect(() => {
        GetLookupStatus();
        GetLookupType();
        GetLookupInvestor();
        GetLookupPlanning();
    }, [])

    const onOpenSelectFile = () => {
        setShow(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShow(false);
        setFiles(filesTemp);
    };

    const onSaveSelectFile = () => {
        setShow(false);
    };

    const onOpenSelectDocument = () => {
        setDocumentShow(true);
        setDocumentsTemp(documents);
    };
    const onCloseSelectDocument = () => {
        setDocumentShow(false);
        setDocuments(documentsTemp);
    };
    const onSaveSelectDocument = () => {
        setDocumentShow(false);
        setFileSelected([...fileSelected, ...documents])
        console.log([...documents])
    };
    const onOpenSelectGallery = () => {
        setGalleryShow(true);
        setGalleryTemp(gallery);
    };
    const onCloseSelectGallery = () => {
        setGalleryShow(false);
        setGallery(galleryTemp);
    };
    const onSaveSelectGallery = () => {
        setGalleryShow(false);
        setGallerySelected([...gallerySelected, ...gallery])
        console.log([...gallery])
    };

    const [shapeFile, setShapeFile] = useState(null);

    const hanleOpenSelectFile = () => { 
        document.getElementById("shapeFile").click();
    }

    const handleFileChange = (event) => {
        clearErrors("shapeFile");
        const selectedFile = event.target.files[0];
        
        if(!selectedFile) {
            setShapeFile(null);
            return;
        };

        setValue("shapeFile", selectedFile)
        if (selectedFile && 
            (
                selectedFile.type === "application/zip" ||
                selectedFile.type === "application/x-zip-compressed" ||
                selectedFile.type === "application/x-compressed"
            )
        ) {
            setShapeFile(selectedFile);
        } else {
            setShapeFile(null);
            ShowNotification(
                "Shape file không phù hợp.",
                NotificationMessageType.Error
            )
        }
    };

    const onClose =() => {
        setShapeFile(null);
        setIsOpen(false);
    }

    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                onClose={onClose}
                aria-labelledby="ConstructionInvestmentForm"
                aria-describedby="ConstructionInvestmentForm_container"
                id="ConstructionInvestmentForm"
                open={isOpen}
            >
                <DialogTitle id="ConstructionInvestmentForm">Thêm thông tin dự án</DialogTitle>
                <DialogContent
                    className="ConstructionInvestmentForm_container"
                    id="ConstructionInvestmentForm_container"
                    dividers
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Tên dự án<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 300 })}
                                    type="text"
                                    name="name"
                                    error={
                                        (errors.name &&
                                            errors.name.type === "required") ||
                                        (errors.name &&
                                            errors.name.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.name &&
                                    errors.name.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.name &&
                                    errors.name.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Quỹ đầu tư<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 300 })}
                                    type="text"
                                    name="Investment"
                                    error={
                                        (errors.Investment &&
                                            errors.Investment.type === "required") ||
                                        (errors.Investment &&
                                            errors.Investment.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.Investment &&
                                    errors.Investment.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.Investment &&
                                    errors.Investment.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Quy mô<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 300 })}
                                    type="text"
                                    name="Scale"
                                    error={
                                        (errors.Scale &&
                                            errors.Scale.type === "required") ||
                                        (errors.Scale &&
                                            errors.Scale.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.Scale &&
                                    errors.Scale.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.Scale &&
                                    errors.Scale.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Địa chỉ<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 300 })}
                                    type="text"
                                    name="Place"
                                    error={
                                        (errors.Place &&
                                            errors.Place.type === "required") ||
                                        (errors.Place &&
                                            errors.Place.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.Place &&
                                    errors.Place.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.Place &&
                                    errors.Place.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Loại dự án
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={typeModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={type}
                                    onChange={(event, newValue) => {
                                        setType(newValue);
                                        setValue("typeId", newValue?.id)
                                        clearErrors("typeId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="typeId"
                                            {...register('typeId', { required: true })}
                                            error={
                                                errors.typeId &&
                                                errors.typeId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.typeId &&
                                    errors.typeId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Trạng thái
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={statusModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={status}
                                    onChange={(event, newValue) => {
                                        setStatus(newValue);
                                        setValue("status", newValue?.id)
                                        clearErrors("status");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="status"
                                            {...register('status', { required: true })}
                                            error={
                                                errors.status &&
                                                errors.status.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.status &&
                                    errors.status.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Chủ đầu tư
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={investorModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={investorSelect}
                                    onChange={(event, newValue) => {
                                        setInvestorSelect(newValue);
                                        setValue("investor", newValue?.id)
                                        clearErrors("investor");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="investor"
                                            {...register('investor', { required: true })}
                                            error={
                                                errors.investor &&
                                                errors.investor.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.investor &&
                                    errors.investor.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Sắp xếp<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 50 })}
                                    type="number"
                                    name="order"
                                    error={
                                        (errors.order &&
                                            errors.order.type === "required") ||
                                        (errors.order &&
                                            errors.order.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        maxLength: 50,
                                        pattern: "[0-9]",
                                        inputMode: "decimal",
                                        min: 0,
                                        step: 1,
                                    }}
                                />
                                {errors.order &&
                                    errors.order.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.order &&
                                    errors.order.type === "maxLength" && (
                                        <span className="error">Tối đa 50 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">Mô tả</label>
                                <textarea
                                    name="note"
                                    rows="5"
                                    className="form-control"
                                    ref={register}
                                ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">
                                    Quy hoạch liên quan
                                </label>
                                <Autocomplete
                                    options={planningModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={planningDetailId}
                                    onChange={(event, newValue) => {
                                        setPlanningDetailId(newValue);
                                        setValue("planningDetailId", newValue?.id)
                                        clearErrors("planningDetailId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="planningDetailId"
                                            {...register('planningDetailId')}
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />

                            </div>
                        </div>
                        {
                            openCreateByShapeFile && 
                            <div className="form-group">
                                <label className="text-dark">Shape File<span className="required"></span></label>
                                
                                {
                                    shapeFile && shapeFile.type === "application/x-zip-compressed" &&
                                    <div style={{ width: "150px" }}>
                                        <img
                                            src={require("../../../../assets/images/zip-file-format.png")}
                                            alt={shapeFile?.name || 'Default'}
                                            className="img-fluid mb-2"
                                            style={{
                                                width: "auto",
                                                height: "auto",
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                            }}
                                        />
                                        <p style={{color: 'black'}} className="error">{shapeFile?.name}</p>
                                    </div>
                                }

                                {
                                    shapeFile && shapeFile.type === "application/x-compressed" &&
                                    <div style={{ width: "150px" }}>
                                        <img
                                            src={require("../../../../assets/images/rar-file-format.png")}
                                            alt={shapeFile?.name || 'Default'}
                                            className="img-fluid mb-2"
                                            style={{
                                                width: "auto",
                                                height: "auto",
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                            }}
                                        />
                                        <p style={{color: 'black'}} className="error">{shapeFile?.name}</p>
                                    </div>
                                }

                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={hanleOpenSelectFile}
                                    >
                                        Chọn file
                                    </Button>
                                    <input
                                        id="shapeFile"
                                        inputRef={register({ required: true })}
                                        name="shapeFile"
                                        type="file"
                                        accept=".zip, .rar"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <div>{errors.shapeFile}</div>
                                    {errors.shapeFile && errors.shapeFile.type === "required" && (
                                        <p className="error">Trường này là bắt buộc</p>
                                    )}
                                </div>
                            </div>
                        }
                        <div className="form-group">
                            <label className="text-dark">File đính kèm</label>
                            <div className="list__img d-flex flex-wrap mt-1">
                                {!isShow &&
                                    fileSelected &&
                                    fileSelected.length > 0 &&
                                    fileSelected.map((item) => (
                                        <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                                            <img
                                                src={require("../../../../assets/icon/default.svg")}
                                                alt={item.fileName}
                                                title={item.fileName}
                                                className="img-fluid mb-2"
                                                style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                            <p className="file_name">{item.fileName}</p>
                                            <p
                                                className="close_x"
                                                onClick={() =>
                                                    setFileSelected(fileSelected.filter(i => i !== item))
                                                }
                                            >
                                                &#10005;
                                            </p>
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onOpenSelectDocument}
                                >
                                    Chọn file
                                </Button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="text-dark">Thư viện ảnh</label>
                            <div className="list__img d-flex flex-wrap mt-1">
                                {!isGalleryShow &&
                                    gallerySelected &&
                                    gallerySelected.length > 0 &&
                                    gallerySelected.map((item) => (
                                        <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                                            <img
                                                src={require("../../../../assets/icon/default.svg")}
                                                alt={item.fileName}
                                                title={item.fileName}
                                                className="img-fluid mb-2"
                                                style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                            <p className="file_name">{item.fileName}</p>
                                            <p
                                                className="close_x"
                                                onClick={() =>
                                                    setGallerySelected(gallerySelected.filter(i => i !== item))
                                                }
                                            >
                                                &#10005;
                                            </p>
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onOpenSelectGallery}
                                >
                                    Chọn file
                                </Button>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">
                                    Ảnh<span className="required"></span>
                                </label>
                                {!isShow &&
                                    files &&
                                    files.length > 0 &&
                                    files.map((item) => (
                                        <div key={item.fileName} style={{ width: "150px" }}>
                                            <img
                                                src={APIUrlDefault + item.filePreview}
                                                alt={item.fileName}
                                                className="img-fluid mb-2"
                                                style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                        </div>
                                    ))}
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onOpenSelectFile}
                                    >
                                        Chọn file
                                    </Button>
                                    <TextField
                                        inputRef={register({ required: true })}
                                        type="hidden"
                                        name="image"
                                        value={(files && files.length > 0 && files[0].fileName) || ""}
                                    />
                                    {errors.image && errors.image.type === "required" && (
                                        <p className="error">Trường này là bắt buộc</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <DialogActions>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            <Button
                                type="submit"
                                style={{ marginLeft: "20px" }}
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                            >
                                Lưu
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>


            </Dialog>
            {isShow && (
                <Dialog
                    onClose={onCloseSelectFile}
                    open={isShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectFile}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            files={files}
                            setFiles={setFiles}
                            acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                            filterExtension={"png"}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectFile}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Thoát
                        </Button>
                        {files && files.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectFile}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            {isDocumentShow && (
                <Dialog
                    onClose={onCloseSelectDocument}
                    open={isDocumentShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectDocument}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            multiple={true}
                            files={documents}
                            fileSelected={fileSelected}
                            setFiles={setDocuments}
                            acceptedFiles={["doc", "docx", "txt", "pdf", "png", "jpg"]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectDocument}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        {documents && documents.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectDocument}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            {isGalleryShow && (
                <Dialog
                    onClose={onCloseSelectGallery}
                    open={isGalleryShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectGallery}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            multiple={true}
                            files={gallery}
                            fileSelected={gallerySelected}
                            setFiles={setGallery}
                            acceptedFiles={["png", "jpg"]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectGallery}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        {gallery && gallery.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectGallery}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default ConstructionInvestmentForm;