import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import * as addressAction from "../../../../redux/store/remote-sensing/address.store";
import OpenLayerView from "../../map-remote-sensing/map/open-layer.view";
import { DropzoneArea } from 'material-ui-dropzone'

//--- Mui
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    Tooltip,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
import FileManagement from "../../../../components/file_management/file_management";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType, APIUrlDefault, } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";

import "./form-remote-sensing.scss";
import dateFormat from "dateformat";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function RemoteSensingHistoryItem(props) {
    const { data, action, index, handleChange, reloadData, isOpen, onClose, isCreateNew, submitAction, showLoading, reflectId } = props;
    const classes = useStyles();

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const [communeModels, setCommuneModels] = useState([]);
    const [curCommune, setCurCommune] = useState();
    const [streetModels, setStreetModels] = useState([]);
    const [curStreet, setCurStreet] = useState();
    const [files, setFiles] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);
    const [isShow, setShow] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [userHandleModel, setUserHandleModel] = useState([]);
    const [userHandleSelected, setUserHandleSelected] = useState([]);

    const today = new Date();
    const fromDate = new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        0,
        0
    );
    const [publishDate, setPublishDate] = useState(fromDate);

    useEffect(() => {
        GetLookupUserhandle();
    }, [])

    const GetLookupUserhandle = () => {
        showLoading(true);
        rsAction.GetLookupUser().then(res => {
            if (res.content && res.content.length > 0) {
                setUserHandleModel(res.content)
                showLoading(false);
            }
        }).catch(err => {
            err &&
                err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            showLoading(false);
        })
    }

    const handleChangeDate = (indexSelect, data, type) => {
        setPublishDate(data);
        handleChange(indexSelect, data, type);
    }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append('IsDelete', false);
        formData.append('ReflectTheSceneId', reflectId)
        formData.append('ActionName', data.title)
        formData.append('Content', data.description)
        formData.append('UserHandlerId', data.userHandleId)
        formData.append('EventDate', dateFormat(publishDate, "dd-mm-yyyy"))
        formData.append('UserHandlerId', data.userHandleId)
        if (files.length > 0) {
            files.map(file => {
                formData.append('GalaryImage', file.fileId)
            })
        }
        if (gallery.length > 0) {
            gallery.map(item => {
                formData.append('galaryImages', item);
            })
        }
        showLoading(true)
        rsAction.CreateHistory(formData).then(res => {
            res && ShowNotification("Thêm mới thành công", NotificationMessageType.Success);
            reloadData();
            onClose();
            showLoading(false);
        }).catch(err => {
            showLoading(false);
            ShowNotification("Xảy ra lỗi vui lòng liên hệ admin", NotificationMessageType.Error);
        });
    }

    const onOpenSelectFile = () => {
        setShow(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShow(false);
        setFiles(filesTemp);
    };

    const onSaveSelectFile = () => {
        setShow(false);

    };

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">{isCreateNew ? 'Thêm mới' : 'Cập nhật'} biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">
                        <div>
                            <div className="form-group">
                                <label className="text-dark fw-6">
                                    Tiêu đề<span className="required"></span>
                                </label>
                                <TextField
                                    name="title"
                                    error={
                                        errors.title &&
                                        (errors.title.type === "required" ||
                                            errors.title.type === "maxLength")
                                    }
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    //   inputProps={{ maxLength: 200 }}
                                    inputRef={register({ required: true, maxLength: 150 })}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) =>
                                        setValue("title", e.target.value)
                                    }
                                />
                                {errors.title && errors.title.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.title && errors.title.type === "maxLength" && (
                                    <span className="error">Trường này không quá 150 ký tự</span>
                                )}
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="text-dark">Nội dung thay đổi</label>
                                            <textarea
                                                name="description"
                                                rows="5"
                                                // onChange={(e) => handleChange(index, e.target.value, 'content')}
                                                ref={register({ maxLength: 500 })}
                                                maxLength="500"
                                                className={
                                                    "form-control"
                                                }
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-6">
                                        <label className="text-dark">
                                            Ngày<span className="required"></span>
                                        </label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                id="publishDate"
                                                name="publishDate"
                                                onChange={(date) => date && setPublishDate(date)}
                                                value={publishDate}
                                                format="dd/MM/yyyy"
                                                fullWidth
                                                showTodayButton={true}
                                                error={
                                                    errors.publishDate &&
                                                    errors.publishDate.type === "required"
                                                }
                                            />
                                        </MuiPickersUtilsProvider>
                                        {errors.publishDate &&
                                            errors.publishDate.type === "required" && (
                                                <span className="error">
                                                    Trường này là bắt buộc
                                                </span>
                                            )}
                                    </div>
                                    <div className="col-6">
                                        {userHandleModel && userHandleModel.length > 0 && (
                                            <div>
                                                <label className="text-dark fw-6">
                                                    Người xử lý<span className="required"></span>
                                                </label>

                                                <Autocomplete
                                                    options={userHandleModel}
                                                    getOptionLabel={(option) =>
                                                        typeof option === "string" ? option : option.userName
                                                    }
                                                    value={userHandleSelected}
                                                    onChange={(event, newValue) => {
                                                        setUserHandleSelected(newValue);
                                                        setValue("userHandleId", newValue.id);
                                                        console.log('newValue', newValue)
                                                    }}
                                                    disableClearable={true}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            name="userHandleId"
                                                            {...register('userHandleId', { required: true })}
                                                            error={
                                                                errors.userHandleId &&
                                                                errors.userHandleId.type === "required"
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                                {errors.userHandleId &&
                                                    errors.userHandleId.type === "required" && (
                                                        <span className="error">Trường này là bắt buộc</span>
                                                    )}
                                            </div>
                                        )}
                                    </div>

                                    {/* <div className="col-1 pt-20">
                                        <Tooltip title="Xóa" className="" hidden={!hasRemove}>
                                            <IconButton onClick={() => action(index)} >
                                                <RemoveCircleIcon fontSize="medium" />
                                            </IconButton>
                                        </Tooltip>
                                    </div> */}

                                    {isShow && (
                                        <Dialog
                                            onClose={onCloseSelectFile}
                                            open={isShow}
                                            fullWidth={true}
                                            maxWidth="md"
                                            className="dialog-preview-form"
                                        >
                                            <DialogTitle disableTypography>
                                                <Typography variant="h6">Quản lý file</Typography>
                                                <IconButton
                                                    aria-label="close"
                                                    className={classes.closeButton}
                                                    onClick={onCloseSelectFile}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </DialogTitle>
                                            <DialogContent dividers>
                                                <FileManagement
                                                    multiple
                                                    files={files}
                                                    setFiles={setFiles}
                                                    acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                                                    filterExtension={'png'}
                                                />
                                            </DialogContent>

                                            <DialogActions>
                                                <Button
                                                    type="button"
                                                    onClick={onCloseSelectFile}
                                                    variant="contained"
                                                    startIcon={<CloseIcon />}
                                                >
                                                    Hủy
                                                </Button>
                                                {files && files.length > 0 && (
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        variant="contained"
                                                        startIcon={<SaveIcon />}
                                                        onClick={onSaveSelectFile}
                                                    >
                                                        Lưu
                                                    </Button>
                                                )}
                                            </DialogActions>
                                        </Dialog>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">

                                        <label className="text-dark">
                                            Ảnh trong hệ thống<span className="required"></span>
                                        </label>
                                        {!isShow &&
                                            files &&
                                            files.length > 0 &&
                                            files.map((item) => (
                                                <div key={item.fileName} style={{ width: "100px" }}>
                                                    <img
                                                        src={APIUrlDefault + item.filePreview}
                                                        alt={item.fileName}
                                                        className="img-fluid mb-2"
                                                        style={{
                                                            width: "auto",
                                                            height: "auto",
                                                            maxWidth: "100%",
                                                            maxHeight: "100%",
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        <div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onOpenSelectFile}
                                            >
                                                Chọn file
                                            </Button>
                                            <TextField
                                                inputRef={register({ required: true })}
                                                type="hidden"
                                                name="image"
                                                value={
                                                    (files && files.length > 0 && files[0].fileName) || ""
                                                }
                                            />
                                            {errors.image && errors.image.type === "required" && (
                                                <p className="error">Trường này là bắt buộc</p>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark fw-6">
                                            Ảnh ngoài hệ thống<span className="required"></span>
                                        </label>
                                        <DropzoneArea
                                            filesLimit={99}
                                            acceptedFiles={['image/*']}
                                            maxFileSize={500000000}
                                            onChange={(files) => setGallery(files)}
                                            showFileNames={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions className="border-top">
                        <Button
                            type="submit"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RemoteSensingHistoryItem);