import React, { useEffect } from "react";
//--- Mui
import { IconButton, Tooltip } from "@material-ui/core";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from "@material-ui/icons/History";
import CollectionsIcon from '@material-ui/icons/Collections';
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { formatAddress } from "../../helper/helper";
import { functionShowOverlayFromSidebar } from "../map/controller";
import dateFormat from "dateformat";
import apiConfig from "../../../../api/api-config";

function ListRemoteSensing(props) {
    const { data, overlayRef, mapRef } = props;
    useEffect(() => { console.log(data) }, [data])
    return (
        <div className="list_rs_container">
            {data && data.map((item, index) => {
                let imgSrc = null;
                let status = null;
                if(item.reflectionStatusId == 2) {
                    imgSrc = require("../img/red-dot.png");
                    status = "Đang xử lý";
                } else {
                    imgSrc = require("../img/green-dot.png");
                    status = "Đã xử lý";
                }
                return (
                    <div key={item.id}>
                        <div className="rs_item">
                            <div className="rs_item_">
                                <div className="dot_status">
                                    <img src={imgSrc} alt="status" title={status}/>
                                </div>
                                <div className="rs_address">
                                    <div className="title">{item?.title}</div>
                                    <div className="address">{formatAddress(item)}</div>
                                    <div className="coord">{item?.latitude}, {item?.longitude}</div>
                                </div>
                            </div>
                            <a
                                className="button_collapse collapsed"
                                data-toggle="collapse"
                                href={`#collapse-${index}`}
                                role="button"
                                aria-expanded="false"
                                aria-controls={`collapse-${index}`}
                            >
                                <Tooltip title="Mở rộng" className="arrow_left">
                                    <IconButton>
                                        <ArrowLeftIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip className="arrow_down" title="Thu gọn">
                                    <IconButton>
                                        <ArrowDropDownIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </a>
                        </div>
                        <div className="collapse" id={`collapse-${index}`}>
                            <div className="card card-body">
                                <div className="list_btn">
                                    {/* <Tooltip title="Chỉnh sửa">
                                        <IconButton>
                                            <EditIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip> */}
                                    {/* <Tooltip title="Lịch sử">
                                        <IconButton>
                                            <CollectionsIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip> */}
                                    <Tooltip title="Xem trên bản đồ">
                                        <IconButton
                                            onClick={() => functionShowOverlayFromSidebar(mapRef.current, overlayRef.current, { coordinate: [item.longitude, item.latitude] })}
                                        >
                                            <MyLocationIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="list_img">
                                    {item?.reflectTheSceneHistoryViewModel &&
                                        item.reflectTheSceneHistoryViewModel.map((historyItem, historyIndex) => (
                                            <div key={`img-${historyIndex}`} className="list_img_item">
                                                <div className="date">
                                                    {dateFormat(historyItem.eventDate, "dd/mm/yyyy")}
                                                </div>
                                                <div className="list">
                                                    {historyItem.galaryImageFile.map((img, imgIndex) => (
                                                        <div key={imgIndex} className="img">
                                                            <img src={apiConfig.api + img} alt="" />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ListRemoteSensing;