import React, { useState, useEffect, useRef } from "react";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import dateformat from "dateformat";
import * as notificationStore from "../../../redux/store/notification/notification.store";
import { getCookies, getUserInfo } from "../../../utils/configuration";
import history from "../../../common/history";
import ListIcon from '@material-ui/icons/List';
import { UrlCollection } from "../../../common/url-collection";
import Dropdown from 'react-bootstrap/Dropdown';

import "./notification.scss";

const Notifications = (props) => {
  const { data, refreshData } = props;
  const wrapperRef = useRef(null);

  const user = getUserInfo();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickNoti = (id, resourceId, type) => {

  }

  const handleClick = (event) => {
    // Check if the clicked element is outside the component
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleClickOutside();
    }
  };

  const handleClickOutside = (isbutton) => {
    if (isbutton) {
      setIsOpen(!isOpen)
    } else {
      setIsOpen(false)
    }
  };

  useEffect(() => {
    // Attach the event listener on component mount
    document.addEventListener('click', handleClick);

    // Detach the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleChangeReadStatus = (id) => {
    notificationStore.ChangeReadStatus(id, user.userId, getCookies('DeviceId')).then(res => {
      if (res.content.status) {
        refreshData();
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const goPageManagenent = (resourceId, type, id) => {
    handleChangeReadStatus(id);
    const url = type === 'ReflectTheScene' ? '/danh-sach-bien-dong' : '/danh-sach-cho';
    history.push({
      pathname: url,
      state: { resourceId: resourceId },
    })
  }

  const goMapView = (resourceId, id) => {
    handleChangeReadStatus(id);
    window.location.replace(`${UrlCollection.MapRemoteSensing}?id=${resourceId}`);
  }

  return (
    <div ref={wrapperRef} className="notifications">
      <div onClick={() => handleClickOutside(true)}>
        <IconButton aria-label="Thông báo" title="Thông báo">
          <Badge color="secondary" badgeContent={data.filter(notification => !notification.isRead).length} showZero>
            <NotificationsActiveIcon fontSize="small" className="mr-2" />
          </Badge>
        </IconButton>
      </div>
      {isOpen && data && data.length > 0 && (
        <div className="noti_menu">
          {data.map((item) => (
            <div key={item.id}>
              <div
                onClick={() => handleClickNoti(item.id, item.resourceId, item.type)}
                className={`dropdown_item ${!item.isRead ? 'unread' : ''}`}
              >
                <p className="noti_content">{item.content}</p>
                <p className="noti_date">{dateformat(item.createdDate, "HH:mm ngày dd/mm/yyyy")}</p>
                <div className="noti_options">
                <Dropdown>
                  <Dropdown.Toggle>
                    <ListIcon fontSize="small"/>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleChangeReadStatus(item.id)}>Đánh dấu đã đọc</Dropdown.Item>
                    {item.type === 'ReflectTheScene' && (
                      <Dropdown.Item onClick={() => goMapView(item.resourceId, item.id)}>Xem trên bản đồ</Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={() => goPageManagenent(item.resourceId, item.type, item.id)}>Đến trang quản lý</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              </div>
            </div>
          ))}
        </div>
      )}

    </div>
  )
}

export default Notifications;