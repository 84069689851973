/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
//--- Action
import * as placeKindAction from "../../../redux/store/place-kind/place-kind.store"
//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditLandTypeManagement(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    landTypeId,
    GetListLandType,
    rowsPerPage,
    showLoading,
    placeKindModels,
    placeTypeModels
  } = props;
  const [placeTypeId, setPlaceTypeId] = useState();
  const [placeKind, setPlaceKind] = useState();

  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    showLoading(true);
    placeKindAction
      .GetDetail(landTypeId)
      .then((res) => {
        if (res && res.content) {
          setPlaceKind(res.content);
          setPlaceTypeId(res.content.placeTypeId)
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    placeKindAction
      .Update({
        ...data,
        id: landTypeId,
        placeTypeId: placeTypeId
      })
      .then((result) => {
        if (result) {
          GetListLandType(1, rowsPerPage);
          onSuccess();
          ShowNotification(
            viVN.Success.EditLandType,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  const handleOnchangePlaceType = (event, newValue) => {
    if (newValue) {
      setPlaceTypeId(newValue.id);

    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa chi tiết loại địa điểm</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {placeKind && (
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Tên chi tiết loại địa điểm<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="name"
                    defaultValue={placeKind.name}
                    className="w-100"
                    inputRef={register({ required: true })}
                    error={errors.name && errors.name.type === "required"}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Mã chi tiết loại địa điểm<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="code"
                    defaultValue={placeKind.code}
                    className="w-100"
                    inputRef={register({
                      required: true,
                      // pattern: /^([0-9]{6})$/g
                    })}
                    disabled={true}
                    error={errors.code && errors.code.type === "required"}
                  />
                  {errors.code && errors.code.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    {placeTypeModels && placeTypeModels.length > 0 && (
                  <Autocomplete
                    id="placeType"
                    options={placeTypeModels}
                    value={{
                      id: placeKind.placeType.id,
                      name: placeKind.placeType.name,
                    }}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    onChange={(event, newValue) =>
                      handleOnchangePlaceType(event, newValue)
                    }
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Chọn loại địa điểm (*)"
                        name={`placeTypeId`}
                        inputRef={register({ required: true })}
                        size="small"
                        variant="outlined"
                        error={
                          errors[`placeTypeId`] &&
                          errors[`placeTypeId`].type === "required"
                        }
                      />
                    )}
                  />
                )}
                  </div>
              </div>
            </div>

          </DialogContent>
          )}
          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
