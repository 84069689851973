import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListAddress = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    name && params.append("keyword", name.trim());
    return service.get("/api/admin/Address/GetList", params).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetailAddress = (id) => {
    return service.get(ApiUrl.GetDetailAddress(id)).then(res => { return res }).catch(err => { throw err });
}
export const GetLookupAddress = (communeId) => {
    const params = new URLSearchParams();
    params.append('communeId', communeId)
    return service.get(ApiUrl.GetLookupAddress.trim(), params).then(res => { return res }).catch(err => { throw err });
}

export const CreateAddress = (body) => {
    return service.post(ApiUrl.CreateAddress, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateAddress = (body) => {
    return service.post(ApiUrl.UpdateAddress, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteAddress = (id) => {
    return service.postParams(ApiUrl.DeleteAddress(id)).then(res => { return res }).catch(err => { throw err });
}

export const AutoSuggestionAddress = async (name) => {
    const params = new URLSearchParams();
    params.append("pageSize", 100);
    params.append("pageIndex", 1);
    name && params.append("keyword", name);
    try {
      const res = await service.get(ApiUrl.GetAutoCompleteAddress, params);
      return res;
    } catch (err) {
      throw err;
    }
  }