import Service from "../../../api/api-service-rs";
import { ApiUrl } from "../../../api/api-url-rs";
import * as config from "../../../common/config"

const service = new Service();

//---province

export const GetListProvince = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    name && params.append("name", name.trim());
    return service.get(ApiUrl.GetListProvince, params).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetailProvince = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.get(ApiUrl.GetDetailProvince.trim(), params).then(res => { return res }).catch(err => { throw err });
}

export const GetLookupProvince = () => {
    return service.get(ApiUrl.GetLookupProvince).then(res => { return res }).catch(err => { throw err });
}

export const CreateProvince = (body) => {
    return service.post(ApiUrl.CreateProvince, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateProvince = (body) => {
    return service.post(ApiUrl.UpdateProvince, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteProvince = (id) => {
    const params = new URLSearchParams();
    params.append("ProvinceId", id);
    return service.postParams(ApiUrl.DeleteProvince, params).then(res => { return res }).catch(err => { throw err });
}

//---District

export const GetListDistrict = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "") => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);
  name && params.append("name", name.trim());
  return service.get(ApiUrl.GetListDistrict, params).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetailDistrict = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service.get(ApiUrl.GetDetailDistrict, params).then(res => { return res }).catch(err => { throw err });
}

export const CreateDistrict = (body) => {
  return service.post(ApiUrl.CreateDistrict, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateDistrict = (body) => {
  return service.post(ApiUrl.UpdateDistrict, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteDistrict = (id) => {
  const params = new URLSearchParams();
  params.append("Id", id);
  return service.postParams(ApiUrl.DeleteDistrict, params).then(res => { return res }).catch(err => { throw err });
}
export const GetDistrictByProvinceId = (id) => {
  const params = new URLSearchParams();
  params.append("provinceId", id);
  return service.get(ApiUrl.GetDistrictByProvinceId, params).then(res => { return res }).catch(err => { throw err });
}

//---Commune

export const GetListCommune = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "") => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sortExpression && params.append("sortExpression", sortExpression);
  name && params.append("name", name.trim());
  return service.get("/api/admin/Administrative/GetListCommune", params).then((res) => { return res }).catch(err => { throw err });
}
export const GetCommuneByDistrictId = (id) => {
  const params = new URLSearchParams();
  params.append("DistrictId", id);
  return service.get(ApiUrl.GetCommuneByDistrictId, params).then(res => { return res }).catch(err => { throw err });
}
export const GetDetailCommune = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service.get(ApiUrl.GetDetailCommune.trim(), params).then(res => { return res }).catch(err => { throw err });
}
export const GetLookupCommune = () => {
  return service.get(ApiUrl.GetLookupCommune.trim()).then(res => { return res }).catch(err => { throw err });
}

export const CreateCommune = (body) => {
  return service.post(ApiUrl.CreateCommune, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateCommune = (body) => {
  return service.post(ApiUrl.UpdateCommune, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteCommune = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service.postParams(ApiUrl.DeleteCommune, params).then(res => { return res }).catch(err => { throw err });
}

//---Street
export const GetStreetByCommuneId = (id) => {
  const params = new URLSearchParams();
  params.append("CommuneId", id);
  return service.get(ApiUrl.GetStreetByCommuneId, params).then(res => { return res }).catch(err => { throw err });
}

//---Address

export const CreateAddress = (body) => {
  return service.post(ApiUrl.CreateAddress, body).then(res => { return res }).catch(err => { throw err });
}

export const AutoSuggestionAddress = async (name) => {
  const params = new URLSearchParams();
  params.append("PageSize", 100);
  name && params.append("Name", name);
  try {
    const res = await service.get(ApiUrl.GetAllAddress, params);
    return res;
  } catch (err) {
    throw err;
  }
}