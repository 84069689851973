export const ApiUrl = {
    //--- Account
    Login: "api/Account/Login",
    Register: "api/Account/Register",
    FirebaseRegister: 'api/Account/FirebaseRegister',
    ForgotPassword: "api/Account/ForgotPassword",
    ResetPassword: "api/Account/ResetPassword",
    //--- address
    GetAllAddress: "api/admin/Address/GetAll",
    CreateAddress: "api/admin/Address/Create",
    UpdateAddress: "api/admin/Address/Update",
    DeleteAddress: "api/admin/Address/Delete",
    DeletePermanentAddress: "api/admin/Address/DeletePermanent",
    // Street
    GetStreetByCommuneId: "api/admin/Street/GetAll",
    // CommuneManagement
    GetListCommune: "api/admin/Administrative/GetListCommune",
    GetDetailCommune: "api/admin/Administrative/GetDetailCommune",
    GetDetailCommune: "api/admin/Administrative/GetDetailCommune",
    CreateCommune: "api/admin/Administrative/CreateCommune",
    UpdateCommune: "api/admin/Administrative/UpdateCommune",
    DeleteCommune: "api/admin/Administrative/DeleteCommune",
    GetCommuneByDistrictId: "api/admin/Commune/GetAll",
    GetLookupCommune: "api/admin/Administrative/GetLookupCommune",

    // DistrictManagement
    GetListDistrict: "api/admin/Administrative/GetListDistrict",
    GetDetailDistrict: "api/admin/Administrative/GetDetailDistrict",
    GetLookupDistrict: "api/admin/Administrative/GetLookupDistrict",
    CreateDistrict: "api/admin/Administrative/CreateDistrict",
    UpdateDistrict: "api/admin/Administrative/UpdateDistrict",
    DeleteDistrict: "api/admin/Administrative/DeleteDistrict",

    // ProvinceManagement
    GetListProvince: "api/admin/Administrative/GetListProvince",
    GetDetailProvince: "api/admin/Administrative/GetDetailProvince",
    CreateProvince: "api/admin/Administrative/CreateProvince",
    UpdateProvince: "api/admin/Administrative/UpdateProvince",
    DeleteProvince: "api/admin/Administrative/DeleteProvince",
    GetLookupProvince: "api/admin/Administrative/GetLookupProvince",

    //---Status
    GetAllStatus: "/api/admin/ReflectionStatus/GetAll",
    GetLookupStatus: "/api/admin/ReflectionStatus/GetLookup",
    CreateStatus: "/api/admin/ReflectionStatus/Create",
    UpdateStatus: "/api/admin/ReflectionStatus/Update",
    DeleteStatus: "/api/admin/ReflectionStatus/Delete",
    Deleteparmanent: "/api/admin/ReflectionStatus/DeletePermanent",
}