import React, { useEffect, useState } from 'react';
import './left-control.scss';
import RenderBasemap from './baseMap/baseMap.view';
import { connect } from 'react-redux';
import RenderLayerControlView from './layers/layers.jsx';
import ConstructionInvestmentView from "./constructionInvestment/ConstructionInvestment.view"
import { bindActionCreators } from 'redux';
import * as InitMapStore from '../../../redux/store/init-map/init-map.store';
function LeftControlView(props) {
    const {setCoordinate, gid, setGid, tableName, setTableName} = props;
    useEffect(() => {
        setClientSetting(props.settings);
    }, [props.settings])

    const [clientSetting, setClientSetting] = useState();

    const handleOpenPopup = () => {
        props.UpdateOpenGeneralSettingModal(true)
    }

    return (
        <span>
            {
                clientSetting && !props.isProject ? (
                    <div className="h-100 left-control-container p-2" style={{ overflowY: "auto" }}>
                        <RenderMapHeader click={() => handleOpenPopup()} clientSetting={clientSetting} />
                        <RenderBasemap selectBaseMapFunction={props.controlOpenlayer} />
                        <RenderLayerControlView selectToggleLayerFunction={props.controlOpenlayer} planningId={props.planningId} />
                        {/* <MapToolPanel/> */}
                    </div>
                ) : (
                    <ConstructionInvestmentView
                        selectToggleLayerFunction={props.controlOpenlayer}
                        planningId={props.planningId}
                        isProject={props.isProject}
                        projectModel={props.projectModel}
                        dataItems={props.dataItems? props.dataItems: null}
                        GetListProject={props.GetListProject}
                        setCoordinate={setCoordinate}
                        gid={gid}
                        setGid={setGid}
                        tableName={tableName}
                        setTableName={setTableName}
                    />
                )
            }
            </span>
    )
}

function RenderMapHeader(props) {

    return (
        <div className="left-control-map-title-container row no-gutters pt-2 pb-2 alignItems-baseline" style={{background: props.clientSetting.color}}>
            <div className="col-8 pl-3">
                <h5> <img className="mr-2" src={require('../../../assets/icon/map.svg')} alt="map" /> Bản đồ</h5>
            </div>
            <div className="col-4 text-right">
                <img onClick={() => props.click()} className="setting mr-4" src={require('../../../assets/icon/settings.svg')} alt="setting" title="Thiết lập thông số chung" />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    controlOpenlayer: state.openLayer.handleOutSideFunction,
    settings: state.clientSetting.clientSetting,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    UpdateOpenGeneralSettingModal: InitMapStore.UpdateOpenGeneralSettingModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LeftControlView)
