import React from "react";
import "./open-layer.scss";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { Image as ImageLayer } from "ol/layer";
import OSM from "ol/source/OSM";
import ImageWMS from "ol/source/ImageWMS";
import XYZ from "ol/source/XYZ";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as OpenlayerStore from "../../redux/store/openlayer/openlayer.store";
import OpenlayerModelCollect from "../../models/open-layer/props-state";
import BaseMapModels from "../../models/init-map-state/base-map";
import LayerModels from "../../models/init-map-state/layers";
import ControlFunctionType from "./control-function-type/control-function-type";
import * as config from "../../utils/configuration";
import VectorImageLayer from "ol/layer/VectorImage";
import Jquery from "jquery";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Stroke, Style } from "ol/style";
import { click } from "ol/events/condition";
import Select from "ol/interaction/Select";
import { Highlight_Feature_Style } from "../../modules/init-map-data/config/config";

var selectClick = new Select({
  condition: click,
  style: Highlight_Feature_Style
});
var select: any = null;

class OpenlayerMapView extends React.Component<
  OpenlayerModelCollect.OpenlayerMapViewPropsModel,
  OpenlayerModelCollect.OpenlayerMapViewStateModel
> {
  constructor(props: any) {
    super(props);
    this.state = {
      map: null,
      mapContainerStyle: null,
      isLoadedAllVectorSource: false,
      currentZoom: 0,
    };
  }

  outSideHandleFunction = (type: string, option: any) => {
    switch (type) {
      case ControlFunctionType.ToggleDisplayLayer:
        {
          let indexRemove = -1;
          const LayerChange: LayerModels.layer_settingsModel = option.layer;

          const listLayerCollection = this.state.map.getLayers();
          listLayerCollection.forEach((event: any, index: number) => {
            if (
              event.values_.source.params_ &&
              event.values_.source.params_.LAYERS ===
                `${config.WorkSpace}:${LayerChange.table}`
            ) {
              indexRemove = index;
              return;
            }
          });

          if (indexRemove !== -1) {
            listLayerCollection.removeAt(indexRemove);
          } else
            listLayerCollection.push(
              new ImageLayer({
                extent: this.props.mapSetting.extent,
                source: new ImageWMS({
                  url: LayerChange.wms,
                  params: {
                    FORMAT: "image/png",
                    VERSION: "1.1.0",
                      STYLES: "",
                      LAYERS: `${config.WorkSpace}:${LayerChange.table}`,
                  },
                  ratio: 1,
                }),
              })
            );
        }
        break;

      case ControlFunctionType.SelectDisplayBasemap:
        {
          const BaseMapChange: BaseMapModels.base_mapsModel = option.basemap;
          const listLayerCollection = this.state.map.getLayers();

          if (BaseMapChange.baseMapSettingModel.layer_type !== "NONE") {
            listLayerCollection.insertAt(
              0,
              new TileLayer({
                source: new XYZ({
                  url: BaseMapChange.url,
                }),
              })
            );
            listLayerCollection.removeAt(1);
          } else {
            listLayerCollection.item(0).setVisible(false);
          }
        }
        break;
      case ControlFunctionType.ClickProjectItem :
        {
          console.log('option', option);
          this.functionClickViewInfomationListener({ coordinate: option.coordinate });
          const map = this.state.map;
          map.getView().animate({
            zoom: 18,
            center : option.coordinate,
            duration: 250
          })
        }
        break;
      default: {
        // console.log('have un set type:' + type)
        // console.log(option)
      }
    }
  };

  handleOnOffViewInfomation = (isTurnOn: boolean) => {
    const mapObject: Map = this.state.map;

    if (isTurnOn) {
      mapObject.on("singleclick", this.functionClickViewInfomationListener);
    } else {
      mapObject.un("singleclick", this.functionClickViewInfomationListener);
    }
    this.changeInteraction();
  };

  functionClickViewInfomationListener = (evt: any) => {
    const mapObject: Map = this.state.map;
    const listLayer = mapObject.getLayers();
    const listTitleLayerVisible: TileLayer[] = [];
    const listImageLayerVisible: ImageLayer[] = [];
    const listVectorImageLayerVisible: VectorImageLayer[] = [];
    const view = mapObject.getView();
    const viewResolution = view.getResolution();
    
    let layerIndex = 0;
    console.log('CLick to map and view info');
    
    const getFeaturesWithTitleLayer = (titleLayer: TileLayer | ImageLayer) => {
      // console.log('titleLayer_xxx',titleLayer);
      const source: any = titleLayer.getSource();
      const layerId = source.getParams()['LayerId'];
      const url = source.getFeatureInfoUrl(
        evt.coordinate,
        viewResolution,
        view.getProjection(),
        { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
      );


      if (url) {
        Jquery.ajax({
          type: "POST",
          url: url,
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: (featureCollection: any) => {
            if (featureCollection.features.length > 0) {
              const _geojsonObject = featureCollection;

              /*
              * Lay thong tin de hien len bang thuoc tinh ben tay trai
              * */

              const featureInfo: any = featureCollection.features[0];
              _geojsonObject.features = [featureInfo];

              if (!this.state.isLoadedAllVectorSource) {
                let _isHaveHighlightVectorlayer = false;
                listLayer.forEach((_layer) => {
                  if ((_layer instanceof VectorLayer) && (_layer.getClassName() === `highlight-vectorlayer`)) {
                      _isHaveHighlightVectorlayer = true;
                    const _newVectorSource = new VectorSource({
                      features: new GeoJSON().readFeatures(_geojsonObject),
                    })
                    _layer.setSource(_newVectorSource)
                  }
                })
                console.log('_isHaveHighlightVectorlayer', _isHaveHighlightVectorlayer);
                if (!_isHaveHighlightVectorlayer) {
                  listLayer.push(
                    new VectorLayer({
                      className: `highlight-vectorlayer`,
                      source: new VectorSource({
                        features: new GeoJSON().readFeatures(_geojsonObject),
                      }),
                      zIndex: 999,
                      style: new Style({
                        stroke: new Stroke({
                          color: 'rgba(0,230,241,1)',
                          width: 2,
                        }),
                        fill: new Fill({
                          color: 'rgba(223,16, 188,0.5)',
                        }),
                      }),
                    })
                  )
                }
              }
            } else {
              // this.functionClickShowPopUp(null);
              // this.removeHiglightVectorLayer();
              layerIndex++;
              if (layerIndex < listTitleLayerVisible.length) {
                getFeaturesWithTitleLayer(listTitleLayerVisible[layerIndex])
              }
            }
          },
        });
      }
    }

    listLayer.forEach((layer) => {
      if ((layer instanceof TileLayer) && (layer.getClassName() != 'ol-layer') && layer.getVisible()) {
        listTitleLayerVisible.push(layer);
      }
      if ((layer instanceof VectorImageLayer) && layer.getVisible()) listVectorImageLayerVisible.push(layer);
      if (layer instanceof ImageLayer && layer.getVisible() ) listImageLayerVisible.push(layer);
    })
    listTitleLayerVisible.reverse();
    listVectorImageLayerVisible.reverse();
    listTitleLayerVisible.sort((a, b) => b.getZIndex() - a.getZIndex());
    if (listTitleLayerVisible.length > 0) {
      getFeaturesWithTitleLayer(listTitleLayerVisible[layerIndex])
    }

    listImageLayerVisible.reverse();
    listVectorImageLayerVisible.reverse();
    listImageLayerVisible.sort((a, b) => b.getZIndex() - a.getZIndex());
    if (listImageLayerVisible.length > 0) {
      getFeaturesWithTitleLayer(listImageLayerVisible[layerIndex])
    }

    console.log("getLayer", listLayer);
    console.log("getOverlays", mapObject.getOverlays());
  };

  changeInteraction() {
    if (!this.state.isLoadedAllVectorSource)
      if (select !== null) {
        this.state.map?.removeInteraction(select);
      }
    select = selectClick;
    if (select !== null) {
      this.state.map?.addInteraction(select);
    }
  }
  
  componentDidMount() {
    const DefaultGroupLayer: any[] = [];
    let listBaseMap = this.props.baseMaps;
    let listLayerGroup = this.props.layers.layer_categories;
    listBaseMap.base_maps.map((baseMap: any) => {
      if (baseMap.view_default) {
        DefaultGroupLayer.push(
          new TileLayer({
            source: new XYZ({
              url: baseMap.url,
            }),
          })
        );
      }
    });

    console.log("componentDidMount",listLayerGroup);

    listLayerGroup.map((layerGroup: any) => {
      layerGroup.layer_settings.map((layer: any) => {
        if (layer.is_check)
          DefaultGroupLayer.push(
            new ImageLayer({
              extent: this.props.mapSetting.extent,
              className: layer.table,
              source: new ImageWMS({
                url: layer.wms,
                params: {
                  FORMAT: "image/png",
                  VERSION: "1.1.0",
                  STYLES: "",
                  LAYERS: `${config.WorkSpace}:${layer.table}`,
                },
                ratio: 1,
              }),
            })
          );
      });
    });

    if (DefaultGroupLayer.length === 0) {
      DefaultGroupLayer.push(
        new TileLayer({
          source: new OSM(),
          visible: false,
        })
      );
    }

    this.props.SetOutSideHandleFunction(this.outSideHandleFunction);
    let mapOpenLayer = new Map({
      target: "map-container-id",
      layers: DefaultGroupLayer,
      view: new View({
        projection: this.props.mapSetting.projection,
        center: this.props.mapSetting.center,
        zoom: this.props.mapSetting.zoom,
      }),
    });

    mapOpenLayer.on('moveend', () => {
      var newZoom = mapOpenLayer.getView().getZoom();
      this.setState({
        currentZoom: newZoom
      })
    })

    this.setState({
      map: mapOpenLayer,
    }, () => {
      // this.handleOnOffViewInfomation(true);
    });

    this.props.setMapLayer && this.props.setMapLayer(mapOpenLayer);

    mapOpenLayer.on("moveend", this.onMoveEnd);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.currentZoom !== prevState.currentZoom) {
      console.log("current zoom", this.state.currentZoom, prevState.currentZoom);
    }
  }

  onMoveEnd = (event: any) => {
    let map = event.map;
    let extend = map?.getView().calculateExtent(map.getSize());
    let zoom = map?.getView().getZoom();
    this.props.setExtend(extend);
    this.props.setZoom(Math.round(zoom));
  };

  render() {
    return (
      <div className="h-100" id="map-container-id">
        {/* <MapToolPanel map={this.state.map} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  mapSetting: state.initMap.mapSetting,
  baseMaps: state.initMap.baseMaps,
  layers: state.initMap.layers,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      SetOutSideHandleFunction: OpenlayerStore.SetHandleOutsideFunction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpenlayerMapView);
