import React, { useEffect, useState } from "react";
//import "./init-map-data.scss";
import OpenlayerMapView from "../../../components/open-layer/open-layer";
import * as InitMapStore from "../../../redux/store/init-map/init-map.store";
import * as appActions from "../../../core/app.store";
import * as GidStore from "../../../redux/store/project-management/gid.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FunctionButton from "../../init-map-data/function-button/function-button";
import LeftControlView from "../../init-map-data/left-control/left-control"
function InitMapDataViewProject(props) {
    const { showLoading } = props;
    const [extend, setExtend] = useState([]);
    const [zoom, setZoom] = useState();
    const [mapLayer, setMapLayer] = useState();
    const [dataItems, setDataItem] = useState();
    const [isFist, setIsFist] = useState(false);
    const [show, setShow] = useState(false);
    const [projectModel, setProjectModel] = useState()
    const [coordinate, setCoordinate] = useState();
    const [gid, setGid] = useState()
    const [tableName, setTableName] = useState()

    console.log("dataItems InitMapViewProj", dataItems);
    useEffect(() => {
        console.log("voos", mapLayer);
        if (props.planningId) {
            if (props.mapId) {
                props.GetMapDetailById(props.mapId, props.planningId);
                setShow(true);
            }
            else props.UpdateDefaultMapData(props.planningId);
        } else props.GetMapDetailById(21, 1);
        props.GetAllBaseMapDefault();
        setMapLayer(undefined);
        setDataItem(undefined);
    }, []);

    useEffect(() => {
        if (isFist) {
            setDataItem(undefined);
        }
        setIsFist(true);
    }, [props.layers]);

    const GetListProject = (gid, tableName) => {
        showLoading(true);
        GidStore.GetDetaiGid(gid, tableName).then(res => {
            setProjectModel(res && res.content ? res.content : null)
            showLoading(false)
        })
    }
    
    const handleGetDataClick = (value) => {
        console.log("handleGetDataClick get", value);
        setDataItem(undefined);
        setDataItem(value);
    };

    useEffect(() => {
        if (dataItems) {
            // GetListProject(parseInt(dataItems.gid), dataItems.table)
            setGid(dataItems.gid);
            setTableName(dataItems.table)
        } else return;
    }, [dataItems]);

    return (
        <div
            className="init-map-data-container h-100 container-fluid p-0"
            style={{ overflowX: "hidden" }}
        >
            <div className="row h-100 p-0 m-0">
                
                <div className={`${props.isProject? "col-4": "col-3"} h-100 p-0`}>
                    <LeftControlView
                        planningId={props.planningId}
                        mapId={props.mapId}
                        isProject={props.isProject}
                        projectModel={projectModel}
                        dataItems={dataItems? dataItems: false}
                        GetListProject={GetListProject}
                        coordinate={coordinate}
                        setCoordinate={setCoordinate}
                        gid={gid}
                        setGid={setGid}
                        tableName={tableName}
                        setTableName={setTableName}
                    />
                </div>
                <div className={`${props.isProject? "col-8": "col-9"} h-100 p-0`}>
                    {props.initMap.hasLoadingData && (
                        <OpenlayerMapView
                            setExtend={setExtend}
                            setZoom={setZoom}
                            setMapLayer={setMapLayer}
                            isProject={props.isProject}
                        />
                    )}
                </div>
            </div>
            <div>
                {mapLayer && (
                    <FunctionButton
                        key={Date.now.toString()}
                        mapLayer={mapLayer}
                        planningName={props.planningName}
                        handleGetDataClick={handleGetDataClick}
                        isProject={props.isProject}
                        coordinate={coordinate}
                        gid={gid}
                        tableName={tableName}
                    />
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    initMap: state.initMap,
    layers: state.initMap.layers,
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            GetMapDetailById: InitMapStore.GetMapDetailById,
            GetAllBaseMapDefault: InitMapStore.GetAllBaseMap,
            UpdateDefaultMapData: InitMapStore.UpdateDefaultMapData,
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(InitMapDataViewProject);
