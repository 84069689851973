const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://caobang-api.cgis.asia/",
    media_url: "https://caobang-api.cgis.asia/",
    domainAdminSide: "http://localhost:3000",
    domainUserSide: "https://caobang.cgis.asia",
    domainName: "",
    workSpace: "caobang",
    wmsBaseLink: "https://geo.cgis.asia/geoserver/caobang/wms",
  },
  production: {
    api: "https://caobang-api.cgis.asia/", 
    media_url: "https://caobang-api.cgis.asia/",
    domainAdminSide: "https://admin.caobang.cgis.asia",
    domainUserSide: "https://caobang.cgis.asia",
    domainName: "caobang.cgis.asia",
    workSpace: "caobang",
    wmsBaseLink: "https://geo.cgis.asia/geoserver/caobang/wms",
  },
};

module.exports = apiEnvironment[env];
