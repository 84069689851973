import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import InitMapDataViewProject from "./init-map-view/init-map-view";
import "./project-management.scss"

const ProjectManagement = (props) => {
    const { showLoading } = props;
    const planningId = 13;
    const mapId = 62;
    const refContent = useRef(null);

    useEffect(() => {
        const header = document.getElementById("header");
        const footer = document.getElementById("footer");

        // Calculate the height
        const windowHeight = window.innerHeight;
        const headerHeight = header ? header.offsetHeight : 0;
        const footerHeight = footer ? footer.offsetHeight : 0;
        const contentHeight = windowHeight - headerHeight - footerHeight;

        console.log("headerHeight",headerHeight);
        console.log("footerHeight",footerHeight);

        // Set the height on refContent
        if (refContent.current) {
            refContent.current.style.height = `${contentHeight-10}px`;
        }
    })

    return (
        <div className="row map_project_container" ref={refContent}>
            <div className="col-12 h-100">
                <InitMapDataViewProject
                    mapId={mapId}
                    planningId={planningId}
                    isProject={true}
                />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);
