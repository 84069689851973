import React, { useEffect, useState, useRef } from "react";
import { UrlCollection } from "../../../../common/url-collection";
import { Link, useLocation } from "react-router-dom";
//---material icons
import ShuffleIcon from "@material-ui/icons/Shuffle";
import HistoryIcon from "@material-ui/icons/History";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import {
    NotificationMessageType,
    DomainAdminSide,
    TokenKey,
    getUserInfo,
    removeCookies,
    APIUrlDefault,
    setCookies,
    getCookies,
} from "../../../../utils/configuration";
import Notifications from "../../notification/notification.view";
import { getMessagingToken, onMessageListener } from "../../../../firebase.ts";
import * as notificationStore from "../../../../redux/store/notification/notification.store";
import ShowNotifications from "../../../../components/show-notifications/show-notifications.view";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

import "./header.scss"

function RemoteSensing(props) {
    const currentLocation = useLocation();
    const isLogin = getUserInfo() ? true : false;
    const user = getUserInfo();
    const audioRef = useRef(null);

    const [listNotifications, setListNotifications] = useState([]);

    useEffect(() => {
        if (isLogin && user && user.userRole) return;
        else {
            removeCookies(TokenKey.returnUrl);
            setCookies(TokenKey.returnUrl, window.location.href);
            window.location.replace(DomainAdminSide + "/dang-nhap");
        }
    }, [user])

    useEffect(() => {
        if (!isMobile) {
            const channel = new BroadcastChannel("notifications");
            channel.addEventListener("message", (event) => {
                console.log("Receive background: ", event.data);
                getListNotification();
            });
            getListNotification();
        }
    }, [])

    const eventListener = () => {
        getListNotification();
        audioRef.current.play();
    }

    const getListNotification = () => {
        if (!isMobile) {
            notificationStore.GetListNotifications(user.userId, getCookies('DeviceId')).then((res) => {
                if (res && res.content) {
                    setListNotifications(res.content)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const onLogout = () => {
        removeCookies(TokenKey.token);
        removeCookies(TokenKey.returnUrl);
        window.location.replace(DomainAdminSide);
    }

    const listMenu = [
        {
            name: "Bản đồ biến động",
            url: UrlCollection.MapRemoteSensing,
            icon: ShuffleIcon,
            active: currentLocation.pathname === UrlCollection.MapRemoteSensing
        },
        {
            name: "Lịch sử biến động",
            url: UrlCollection.HistoryRemoteSensing,
            icon: HistoryIcon,
            active: currentLocation.pathname === UrlCollection.HistoryRemoteSensing
        },
        {
            name: "Kho ảnh hiện trạng",
            url: UrlCollection.PhotoGalleryRemoteSensing,
            icon: PermMediaIcon,
            active: currentLocation.pathname === UrlCollection.PhotoGalleryRemoteSensing
        },
        {
            name: "Quản lý biến động",
            url: UrlCollection.RemoteSensingManagement,
            icon: AssignmentIcon,
            active: currentLocation.pathname === UrlCollection.RemoteSensingPending ||
                currentLocation.pathname === UrlCollection.RemoteSensingList ||
                currentLocation.pathname === UrlCollection.InfomationTableRemoteSensing,
            dropDown: [
                {
                    name: 'Danh sách biến động',
                    url: UrlCollection.RemoteSensingList,
                },
                {
                    name: 'Danh sách chờ',
                    url: UrlCollection.RemoteSensingPending,
                },
                {
                    name: "Bảng biểu thông tin",
                    url: UrlCollection.InfomationTableRemoteSensing,
                }
            ]
        },
    ]

    return (
        <div className="header_container">
            <div className="container mw_1500">
                <nav className="navbar navbar-expand-lg navbar-light ">
                    {!isMobile && (
                        <a className="navbar-brand" href="/">
                            <img
                                src={process.env.PUBLIC_URL + "/logo.png"}
                                alt="Logo"
                                width="50"
                                height="50"
                            />
                            <div className="brand_name">
                                <h3>RSI</h3>
                                <p>technology</p>
                            </div>
                        </a>
                    )}
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav nav_menu">
                            {listMenu.map((item, index) => {
                                if (!item.dropDown) {
                                    return (
                                        <li key={index} className={`nav-item`}>
                                            <Link
                                                className={`nav_item ${item.active ? "active" : ""}`}
                                                to={item.url}
                                            >
                                                <item.icon fontSize="small" className="mr-2" />
                                                <span>{item.name}</span>
                                            </Link>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li key={index} className={`nav-item dropdown`}>
                                            <a className={`nav_item nav-link dropdown-toggle ${item.active ? "active" : ""}`}
                                                href="#"
                                                id="navbarDropdown"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                {item.name}
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                {item.dropDown.map((dropdownItem, dropdownIndex) => (
                                                    <Link
                                                        key={dropdownIndex}
                                                        className="dropdown-item"
                                                        to={dropdownItem.url}
                                                    >
                                                        {dropdownItem.name}
                                                    </Link>
                                                ))}
                                            </div>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                        <ul className="navbar-nav f_c_u d-flex align-items-center">
                            {!isMobile && (
                                <>
                                    <li className="nav-item">
                                        <Notifications
                                            data={listNotifications}
                                            refreshData={getListNotification}
                                        />
                                    </li>
                                    <div className="nav-item dropdown no-arrow">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="userDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {/* <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                                {user && user.fullName}
                                            </span> */}
                                            <img
                                                width="30px"
                                                className="img-profile rounded-circle"
                                                src={//require("../../assets/images/user-default.png"
                                                    user && user.avatar && user.avatar !== "null"
                                                        ? APIUrlDefault + user.avatar
                                                        : process.env.PUBLIC_URL + "/user-default.png"
                                                }
                                            />
                                        </a>

                                        <div
                                            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="userDropdown"
                                        >
                                            {/* <a className="dropdown-item" href="#">
                <FontAwesomeIcon icon={faUser} className="fa-sm fa-fw mr-2 text-gray-400" />Profile
              </a> */}
                                            {/* <div className="dropdown-divider"></div> */}
                                            <a className="dropdown-item" href="" onClick={() => onLogout()}>
                                                <FontAwesomeIcon
                                                    icon={faSignOutAlt}
                                                    className="fa-sm fa-fw mr-2 text-gray-400"
                                                />
                                                Logout
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
            <audio ref={audioRef}>
                <source src={require("../../../../components/show-notifications/notification-sound.mp3")} type="audio/mpeg" />
            </audio>
            <ShowNotifications action={eventListener} />
        </div>
    )
}

export default RemoteSensing;