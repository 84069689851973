import React, { useEffect, useRef, useState } from "react";
import {
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Dialog,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MapIcon from "@material-ui/icons/Map";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as InvestorAction from "../../../../redux/store/investor/investor.store";
import * as GidAction from "../../../../redux/store/project-management/gid.store"
import * as ConstructionInvestmentAction from "../../../../redux/store/project-management/constructionInvestment.store";
import CloseIcon from "@material-ui/icons/Close";
import { ApiUrl } from "../../../../api/api-url.js";
import NotificationService from "../../../../common/notification-service";
import "./constructionInvestmentDetail.scss"
import * as ApiConfig from "../../../../api/api-config"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
//ConstructionInvestmentForm
import ConstructionInvestmentFormEdit from "../EditConstructionInvestment/edit-constructionInvestment.view"
import * as ConstructionAction from "../../../../redux/store/project-management/constructionInvestment.store";
import { DomainUserSite } from "../../../../utils/configuration";

function ConstructionInvestmentDetail(props) {
    const { showLoading,
        gid,
        tableName,
        dataItems,
        setIsOpenCIForm,
        refreshListProject,
        setIsOpenCIDetail,
        GetListAll,
        tabRef,
        containerRef
    } = props;
    const history = useHistory();
    const [name, setName] = useState();
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    const [investorModel, setInvestorModel] = useState([]);
    const [investor, setInvestor] = useState("");
    const [files, setFiles] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);
    const [isShow, setShow] = useState(false);
    const [fileSelected, setFileSelected] = useState([]);
    const [isDocumentShow, setDocumentShow] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [documentsTemp, setDocumentsTemp] = useState([]);

    const [gallerySelected, setGallerySelected] = useState([]);
    const [isGalleryShow, setGalleryShow] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [galleryTemp, setGalleryTemp] = useState([]);
    const [statusModel, setStatusModel] = useState();
    const [planningId, setPlanningId] = useState();
    const [typeModel, setTypeModel] = useState();
    const [projectModel, setProjectModel] = useState([])
    const [galleryModel, setGalleryModel] = useState([])
    const [documentModel, setDocumentModel] = useState([])
    const [isOpenCIDelete, setIsOpenCIDelete] = useState(false);
    const [isOpenCIEdit, setIsOpenCIEdit] = useState(false);

    const settingSlider = {
        //dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const DeleteConstruction = (id) => {
        console.log(id)
        id && ConstructionAction.DeleteConstructionInvestment(id).then((res) => {
            NotificationService.success("Xóa thành công");
            GetListAll(1,10);
            setIsOpenCIDelete(false)
            setIsOpenCIDetail(false)
        })
    }

    useEffect(() => {
        if (gid && tableName)
            GetProjectDetail(gid, tableName)
    }, [gid, tableName])

    useEffect(() => {
        if (dataItems)
            GetProjectDetail(dataItems.gid, dataItems.table)
    }, [dataItems])

    const GetProjectDetail = (gid, tableName) => {
        showLoading(true);
        GidAction.GetDetaiGid(gid, tableName).then(res => {
            if (res) {
                setProjectModel(res.content)
                setDocumentModel(res.content.documentFiles ? res.content.documentFiles : [])
                setGalleryModel(res.content.galleryFiles)
                setPlanningId(res.content.PlanningDetailId ? res.content.PlanningDetailId : 0)
            }
            showLoading(false)
        }).catch(err => showLoading(false))
    }

    useEffect(() => {
        if(!projectModel) {
            setIsOpenCIForm(true);
            return;
        }
        if (projectModel?.typeId && typeModel) {
            setType(typeModel.find((x) => x.id === projectModel.typeId))
        }
        else if (projectModel?.constructionInvestmentTypeId && typeModel) {
            setType(typeModel.find((x) => x.id === projectModel.constructionInvestmentTypeId))
        }
    }, [projectModel, typeModel])
    useEffect(() => {
        if (projectModel?.constructionInvestmentStatusId && statusModel) {
            setStatus(statusModel.find((x) => x.id === projectModel.constructionInvestmentStatusId))
        }
    }, [projectModel, statusModel])
    useEffect(() => {
        if (projectModel?.investorId && investorModel) {
            setInvestor(investorModel.find((x) => x.id === projectModel.investorId))
        }
    }, [projectModel, investorModel])

    const GetLookupInvestor = () => {
        showLoading(true)
        InvestorAction.GetLookUpInvestor().then((res) => {
            setInvestorModel(res && res.content ? res.content : [])
            showLoading(false)
        }).catch(err => showLoading(false))
    }

    const GetLookupStatus = () => {
        showLoading(true)
        ConstructionInvestmentAction.GetLookupStatus().then((res) => {
            setStatusModel(res && res.content ? res.content : [])
            showLoading(false)
        }).catch(err => console.log(err))
    }

    const GetLookupType = () => {
        showLoading(true)
        ConstructionInvestmentAction.GetLookupType().then((res) => {
            setTypeModel(res && res.content ? res.content : [])
            showLoading(false)
        }).catch(err => console.log(err))
    }

    const openNotification = () => {
        NotificationService.warning("Chưa có thêm quy hoạch liên quan cho dự án");
    }
    useEffect(() => {
        GetLookupInvestor();
        GetLookupStatus();
        GetLookupType();
    }, [])

    const handleSelect = (data, id) => {
        if (data) {
            let x = data.find((item) => item.id === id)
            console.log(x)
        }
    }

    const projectInfoRef = useRef(null);
    const containerCIRef = useRef(null);

    useEffect(() => {
        if(!containerRef.current || !tabRef.current || !projectInfoRef.current || !containerCIRef.current) return;
    
        const containerHeight = containerRef.current.offsetHeight;
        const tabHeight = tabRef.current.offsetHeight;
        const projectInfoHeight = projectInfoRef.current.offsetHeight;
        
        const result = containerHeight - tabHeight- projectInfoHeight;
        console.log("result",result);
        containerCIRef.current.style.height = result + 'px';
    })
    
    const formatNote = (note) => {
        const formattedNote = note.split('\r\n').map((item, index) => (
            <React.Fragment key={index}>
              {item}
              <br />
            </React.Fragment>
          ));
        return <p>{formattedNote}</p>;
    }
    return (
        <>
            {projectModel !== null ? (
                <>
                    <div className="project__info" ref={projectInfoRef}>
                        <div className="row header__info">
                            <div className="col-8">
                                <p className="pt-3 pb-3 text-left">{projectModel?.name}
                                </p>
                            </div>
                            <div className="col-4" style={{ float: 'right' }}>
                                {projectModel?.planningDetailId > 0 ?
                                    <a href={`${DomainUserSite}/ban-do-quy-hoach/${projectModel.planningDetailId}`} target="_blank">
                                        <Tooltip title="Xem">
                                            <IconButton
                                                aria-label="visiable"
                                            >
                                                <MapIcon
                                                    color="primary"
                                                    fontSize="small"
                                                />
                                            </IconButton>

                                        </Tooltip>
                                    </a> :
                                    <Tooltip title="Xem quy hoạch liên quan">
                                        <IconButton
                                            className="text-right"
                                            onClick={() =>
                                                openNotification()
                                            }
                                        >
                                            <MapIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                }


                                <Tooltip title="Chỉnh sửa">
                                    <IconButton
                                        className="text-right"
                                        onClick={() =>
                                            setIsOpenCIEdit(true)
                                        }
                                    >
                                        <EditIcon
                                            color="primary"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Xóa">
                                    <IconButton
                                        className="text-right"
                                        onClick={() =>
                                            setIsOpenCIDelete(true)
                                        }
                                    >
                                        <DeleteIcon
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>
                    </div>
                    <div className="container_ci" ref={containerCIRef}>
                        <div className="">
                            <div className="slide">
                                {galleryModel && galleryModel.length > 0 && (
                                    <div>
                                        <Slider {...settingSlider}>
                                            {galleryModel.map(
                                                (item, index) =>
                                                    <div key={index}>
                                                        <img src={ApiConfig.api + item.filePreview} alt={item.fileName} />
                                                    </div>
                                            )}
                                        </Slider>
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="">
                            <div className="name_ci">
                                <h3>{projectModel?.name}</h3>
                                <p>{projectModel?.place}</p>
                            </div>
                            <div className="row content_ci">
                                <div className="col-4 item_ci">
                                    <p>Chủ đầu tư</p>
                                </div>
                                <div className="col-8 value_ci">
                                    <p> {investor?.name}</p>
                                </div>
                            </div>
                            <div className="row content_ci">
                                <div className="col-4 item_ci">
                                    <p>Quỹ đầu tư</p>
                                </div>
                                <div className="col-8 value_ci">
                                    <p> {projectModel?.investment}</p>
                                </div>
                            </div>
                            <div className="row content_ci">
                                <div className="col-4 item_ci">
                                    <p>Loại hình</p>
                                </div>
                                <div className="col-8 value_ci">
                                    <p> {type.name}</p>
                                </div>
                            </div>
                            <div className="row content_ci">
                                <div className="col-4 item_ci">
                                    <p>Quy mô</p>
                                </div>
                                <div className="col-8 value_ci">
                                    <p> {projectModel?.scale}</p>
                                </div>
                            </div>
                            <div className="row content_ci">
                                <div className="col-4 item_ci">
                                    <p>Trạng Thái</p>
                                </div>
                                <div className="col-8 value_ci">
                                    <p> {status?.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="content_ci">
                                {
                                    projectModel && projectModel.note ? formatNote(projectModel.note) : ""
                                }
                            </div>
                            <div className="content_ci">
                                {
                                    documentModel.length > 0 && (<a
                                        style={{ fontWeight: "bold" }}
                                        href={ApiConfig.api + ApiUrl.DownloadFileConstructionInvestment.replace("{id}", projectModel?.id)}
                                        target="_blank"
                                    >
                                        Tải file đính kèm tại đây
                                    </a>)
                                }
                                {documentModel && (
 
                                    documentModel.map((item, index) => (
                                    <div key={index}>
                                        <a
                                            href={ApiConfig.api + item?.filePreview}
                                            target="_blank"
                                            download={item?.fileName}
                                        >
                                            {item?.fileName}
                                        </a>
                                    </div>
                                )))}
                            </div>
                        </div>
                    </div>
                    <ConstructionInvestmentFormEdit
                        isOpen={isOpenCIEdit}
                        dataItems={dataItems}
                        setIsOpen={setIsOpenCIEdit}
                        GetListProject={refreshListProject}
                        projectModel={projectModel}
                        setIsOpenCIDetail={setIsOpenCIDetail}
                        GetListAll={GetListAll}
                    />
                    <Dialog
                        maxWidth="xs"
                        fullWidth={true}
                        onClose={() =>
                            setIsOpenCIDelete(false)
                        }
                        aria-labelledby="ConstructionInvestmentDelete"
                        aria-describedby="ConstructionInvestmentDelete"
                        id="delete-ci"
                        open={isOpenCIDelete}
                    >
                        <DialogTitle id="ConstructionInvestmentDelete">Xác nhận xóa</DialogTitle>
                        <DialogContent>
                            <h4>Bạn có chắc chắn xóa ?</h4>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="button"
                                onClick={() => setIsOpenCIDelete(false)}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            <Button
                                type="button"
                                onClick={() => DeleteConstruction(projectModel.id)}
                                style={{ marginLeft: "20px" }}
                                color="primary"
                                variant="contained"
                                startIcon={<DeleteIcon />}
                            >
                                Lưu
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <>
                    <span className="pl-5">Chưa có thông tin dự án.
                        <Tooltip title="Thêm mới">
                            <IconButton
                                className="text-right"
                                onClick={() =>
                                    setIsOpenCIForm(true)
                                }
                            >
                                <PlaylistAddIcon
                                    color="primary"
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    </span>

                </>
            )}

        </>
    )
}


const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionInvestmentDetail);