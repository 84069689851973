import Img1 from '../map-remote-sensing/img/rs1.png';
import Img2 from '../map-remote-sensing/img/rs2.png';

export const listRS = [
    {
        id: 1,
        name: "Đường Lạc Long Quân",
        coord: [13.23456, 108.65432],
        status: 1,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 2,
        name: "Đường An Dương Vương",
        coord: [13.23456, 108.65432],
        status: 2,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 3,
        name: "Đường Võ Văn Tần",
        coord: [13.23456, 108.65432],
        status: 1,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 4,
        name: "Đường Âu Cơ",
        coord: [13.23456, 108.65432],
        status: 1,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 5,
        name: "Đường Lạc Long Quân",
        coord: [13.23456, 108.65432],
        status: 3,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 6,
        name: "Đường Lạc Long Quân",
        coord: [13.23456, 108.65432],
        status: 2,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 7,
        name: "Đường Lạc Long Quân",
        coord: [13.23456, 108.65432],
        status: 1,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 8,
        name: "Đường Lạc Long Quân",
        coord: [13.23456, 108.65432],
        status: 1,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 9,
        name: "Đường Lạc Long Quân",
        coord: [13.23456, 108.65432],
        status: 1,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
    {
        id: 10,
        name: "Đường Lạc Long Quân",
        coord: [13.23456, 108.65432],
        status: 1,
        date: '06/10/2023',
        list: [
            {
                date: '01/10/2023',
                img: Img1
            },
            {
                date: '06/10/2023',
                img: Img2
            }
        ]
    },
];

export const listByRegion = [
    {
        name: 'Phường Chi Lăng'
    },
    {
        name: 'Phường Diên Hồng'
    },
    {
        name: 'Phường Đống Đa'
    },
    {
        name: 'Phường Hoa Lư'
    },
]

export const listByDate = [
    {
        name: 'Tháng 10/2023'
    },
    {
        name: 'Tháng 09/2023'
    },
    {
        name: 'Tháng 08/2023'
    },
    {
        name: 'Tháng 07/2023'
    },
]

export const listImg = [
    {
        url: Img1
    },
    {
        url: Img2
    },
    {
        url: Img1
    },
    {
        url: Img2
    },
    {
        url: Img1
    },
    {
        url: Img2
    },
    {
        url: Img1
    },
    {
        url: Img2
    },
]

export const listBaseMap = [
    {
        name: "Bản đồ nền giao thông",
        layerType: 'GOOGLE',
        url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    },
    {
        name: "Bản đồ nền vệ tinh",
        layerType: 'VETINH',
        url: "https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
    },
]
