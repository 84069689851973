import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
//--- Mui
import { DropzoneArea } from 'material-ui-dropzone'
import {
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";


function AddShapeFile(props) {
    const { isOpen, onClose, showLoading } = props;
    const { control, register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const [avatar, setAvatar] = useState([]);
    const [gallery, setGallery] = useState([]);

    const onSubmit = data => {

        if (gallery.length > 0 && avatar.length > 0) {
            let formData = new FormData();
            if (avatar.length > 0) formData.append('shpFile', avatar[0]);
            if (gallery.length > 0) {
                gallery.map(item => {
                    formData.append('galaryImages', item);
                })
            }
            showLoading(true)
            rsAction.AddShapeFile(formData).then(res => {
                res && ShowNotification("Cập nhật thành công", NotificationMessageType.Success);
                onClose();
                showLoading(false);
            }).catch(err => {
                showLoading(false);
                ShowNotification("Xảy ra lỗi vui lòng liên hệ admin", NotificationMessageType.Error);
            });
        } else {
            ShowNotification("Vui lòng nhập đầy đủ thông tin", NotificationMessageType.Error, 'center');
        }
    }

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Thêm mới</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-12">
                                    <label className="text-dark fw-6">
                                        Shape file<span className="required"></span>
                                    </label>
                                    <DropzoneArea
                                        filesLimit={1}
                                        acceptedFiles={['.zip', '.rar', '.shp', ".shx", ".dbf"]}
                                        maxFileSize={500000000}
                                        dropzoneText="Drag and drop a .zip or .shp file here or click"
                                        onChange={(files) => setAvatar(files)}
                                        showFileNames={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-12">
                                    <label className="text-dark fw-6">
                                        Danh sách ảnh<span className="required"></span>
                                    </label>
                                    <DropzoneArea
                                        filesLimit={99}
                                        acceptedFiles={['image/*']}
                                        maxFileSize={50000000000}
                                        dropzoneText="Drag and drop image files here or click"
                                        onChange={(files) => setGallery(files)}
                                        showFileNames={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <DialogActions className="border-top">
                            <Button
                                type="submit"
                                onClick={onClose}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                            >
                                Lưu
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddShapeFile);