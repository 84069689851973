import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListPlanning = (pageIndex, pageSize, sortExpression, kindId, queryData = {}) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  params.append("planningKindId", kindId || 1);
  sortExpression && params.append("sortExpression", sortExpression);

  for (const [key, value] of Object.entries(queryData)) {
    if (value) {
        params.append(key, value);
    }
  }

  return service
    .get(ApiUrl.GetListPlanning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailPlaning = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.GetDetailPlaning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpPlanning = () => {
  return service
    .get(ApiUrl.GetLookUpPlanning)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpPlanningStatus = () => {
  return service
    .get(ApiUrl.GetLookUpPlanningStatus)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpPlanningLevel = () => {
  return service
    .get(ApiUrl.GetLookUpPlanningLevel)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpPlanningType = () => {
  return service
    .get(ApiUrl.GetLookUpPlanningType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpDocumentType = () => {
  return service
    .get(ApiUrl.GetLookUpDocumentType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookupLayerByPlanningId = (id) => {
    const params = new URLSearchParams();
    params.append("planningId", id);
    return service
        .get(ApiUrl.GetLookupLayerByPlanningId, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const CreatePlanning = (data) => {
  const formData = new FormData();
  data.PlanningKindId && formData.append("PlanningKindId", data.PlanningKindId);
  data.isOldPlan && formData.append("isOldPlan", data.isOldPlan);
  data.Name && formData.append("Name", data.Name);
  data.PlanningCode && formData.append("PlanningCode", data.PlanningCode);
  data.DocumentUploadId &&
    formData.append("DocumentUploadId", data.DocumentUploadId);
  data.PlanningTypeId && formData.append("PlanningTypeId", data.PlanningTypeId);
  data.Place && formData.append("Place", data.Place);
  data.Order && formData.append("Order", data.Order);
  data.PlanningAgency && formData.append("PlanningAgency", data.PlanningAgency);
  data.ConsultingUnit && formData.append("ConsultingUnit", data.ConsultingUnit);
  data.Investor && formData.append("Investor", data.Investor);
  data.PlanningStatusId &&
    formData.append("PlanningStatusId", data.PlanningStatusId);
  data.AgencySubmitted &&
    formData.append("AgencySubmitted", data.AgencySubmitted);
  data.PlanningUnit && formData.append("PlanningUnit", data.PlanningUnit);
  data.PlanningLevelId &&
    formData.append("PlanningLevelId", data.PlanningLevelId);
  data.Population && formData.append("Population", data.Population);
  data.Acreage && formData.append("Acreage", data.Acreage);
  data.LandForConstruction &&
    formData.append("LandForConstruction", data.LandForConstruction);
  data.Report && formData.append("Report", data.Report);
  data.Note && formData.append("Note", data.Note);
  data.DocumentTypeId && formData.append("DocumentTypeId", data.DocumentTypeId);
  data.PlanningDistrictIds.length > 0 &&
    data.PlanningDistrictIds.map((item) =>
      formData.append("PlanningCommuneIds", item)
    );
  data.ApprovalAgencyLevelId &&
    formData.append("ApprovalAgencyLevelId", data.ApprovalAgencyLevelId);
  data.tifFile &&
    formData.append("tifId", data.tifFile);
  data.shpFile &&
    formData.append("shpId", data.shpFile);
  return service
    .post(ApiUrl.CreatePlanning, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdatePlanning = (data) => {
  const formData = new FormData();
  data.Id && formData.append("Id", data.Id);
  data.PlanningKindId && formData.append("PlanningKindId", data.PlanningKindId);
  data.Name && formData.append("Name", data.Name);
  data.PlanningCode && formData.append("PlanningCode", data.PlanningCode);
  data.DocumentUploadId &&
    formData.append("DocumentUploadId", data.DocumentUploadId);
  data.PlanningTypeId && formData.append("PlanningTypeId", data.PlanningTypeId);
  data.Place && formData.append("Place", data.Place);
  data.Order && formData.append("Order", data.Order);
  data.PlanningAgency && formData.append("PlanningAgency", data.PlanningAgency);
  data.ConsultingUnit && formData.append("ConsultingUnit", data.ConsultingUnit);
  data.Investor && formData.append("Investor", data.Investor);
  data.PlanningStatusId &&
    formData.append("PlanningStatusId", data.PlanningStatusId);
  data.AgencySubmitted &&
    formData.append("AgencySubmitted", data.AgencySubmitted);
  data.PlanningUnit && formData.append("PlanningUnit", data.PlanningUnit);
  data.PlanningLevelId &&
    formData.append("PlanningLevelId", data.PlanningLevelId);
  data.Population && formData.append("Population", data.Population);
  data.Acreage && formData.append("Acreage", data.Acreage);
  data.LandForConstruction &&
    formData.append("LandForConstruction", data.LandForConstruction);
  data.Report && formData.append("Report", data.Report);
  data.Note && formData.append("Note", data.Note);
  data.DocumentTypeId && formData.append("DocumentTypeId", data.DocumentTypeId);
  data.PlanningDistrictIds &&
    data.PlanningDistrictIds.length > 0 &&
    data.PlanningDistrictIds.map((item) =>
      formData.append("CommuneIds", item)
    );
  data.ApprovalAgencyLevelId &&
    formData.append("ApprovalAgencyLevelId", data.ApprovalAgencyLevelId);
  data.tifFile &&
    formData.append("tifId", data.tifFile);
  data.shpFile &&
    formData.append("shpId", data.shpFile);

  return service
    .post(ApiUrl.UpdatePlanning, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const DeletePlanning = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .postParams(ApiUrl.DeletePlanning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const ApprovalAgencyLevel = () => {
  return service
    .get(ApiUrl.ApprovalAgencyLevel)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PlanningRelationshipType = () => {
  return service
    .get(ApiUrl.PlanningRelationshipType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PlanningRelationshipTypeById = (id) => {
  const params = new URLSearchParams();
  params.append("planningId", id);
  return service
    .get(ApiUrl.PlanningRelationshipTypeById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const PlanningApprovedById = (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return service
    .get(ApiUrl.PlanningApprovedById, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookupDistrict = () => {
  return service
    .get(ApiUrl.GetLookupDistrict)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetLookupCommune = () => {
  return service
    .get(ApiUrl.GetLookupCommune)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PutPlanningReletionship = (requestBody, planningId) => {
  const params = new URLSearchParams();
  params.append("planningId", planningId);
  return service
    .put(ApiUrl.PutPlanningRelationship, requestBody, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetAllBoundaries = () => {
  return service
    .get(ApiUrl.GetAllBoundaries)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const CheckExistedPlanning = (planningCode) => {
  const params = new URLSearchParams();
  params.append("planningCode", planningCode);
  return service
    .get(ApiUrl.CheckExistedPlanning, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
