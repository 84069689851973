import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../../core/app.store";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";
import * as viVN from "../../../../../language/vi-VN.json";
import * as helper from "../../../helper/helper";
import * as rsAction from "../../../../../redux/store/remote-sensing/remote-sensing.store";
//--- Mui
import { DropzoneArea } from 'material-ui-dropzone'
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    Tooltip,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SuggestionAddress from "../../../components/suggestion-address/suggestion-address.view";
import FormAddAddress from "../../../components/form/form-add-address.view";

import { convertAddress } from "../../../helper/helper";

function Receive(props) {
    const { isOpen, onClose, isCreateNew, showLoading, pendingId, reloadData } = props;
    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const [isHot, setIsHot] = useState(true);
    const [openAddAddress, setOpenAddAddress] = useState(false);
    const [addressSelected, setAddressSelected] = useState();
    const [statusModel, setStatusModel] = useState([]);
    const [statusSelected, setStatusSelected] = useState();
    const [userHandleModel, setUserHandleModel] = useState([]);
    const [userHandleSelected, setUserHandleSelected] = useState([]);
    const [avatar, setAvatar] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [remoteSensingHistory, setRemoteSensingHistory] = useState([helper.rsHistoryItem]);

    useEffect(() => {
        GetLookupUserhandle();
    }, []);

    const GetLookupUserhandle = () => {
        showLoading(true);
        rsAction.GetLookupUser().then(res => {
            if (res.content && res.content.length > 0) {
                setUserHandleModel(res.content)
                showLoading(false);
            }
        }).catch(err => {
            err &&
                err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            showLoading(false);
        })
    }

    const handleChangeAddress = (data) => {
        setAddressSelected(data);
    }
    useEffect(() => {
        console.log('addressSelected', addressSelected)
    }, [addressSelected])

    const onSubmit = data => {
        console.log('data', { ...data, addressSelected: addressSelected })
        if (addressSelected) {
            let formData = new FormData();
            data.title && formData.append('Title', data.title);
            data.description && formData.append('Content', data.description);
            data.userHandleId && formData.append('UserHandlerId', data.userHandleId);
            formData.append('PendingId', pendingId);
            formData.append('AddressId', addressSelected.id);
            formData.append('ReflectionFieldId', 1);
            formData.append('CategoryId', 1);
            formData.append('ReflectionUnitId', 1);

            // if (avatar.length > 0) formData.append('image', avatar[0]);
            if (gallery.length > 0) {
                gallery.map(item => {
                    formData.append('image', item);
                })
            }
            showLoading(true)
            rsAction.ReceivePending(formData).then(res => {
                res && ShowNotification("Cập nhật thành công", NotificationMessageType.Success);
                onClose();
                showLoading(false);
                reloadData();
            }).catch(err => {
                showLoading(false);
                ShowNotification("Xảy ra lỗi vui lòng liên hệ admin", NotificationMessageType.Error);
            });
        } else {
            ShowNotification("Vui lòng chọn địa chỉ hoặc thêm mới", NotificationMessageType.Error, 'center');
        }

    }

    const handleChangeHistory = (indexSelect, data, type) => {
        console.log('data', data, type)
        let curr = remoteSensingHistory;
        curr[indexSelect][type] = data;
        setRemoteSensingHistory(curr);
        console.log('curr', curr)
    }

    useEffect(() => { console.log('remoteSensingHistory', remoteSensingHistory) }, [remoteSensingHistory])
    return (
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md" className="form_rs">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">{isCreateNew ? 'Thêm mới' : 'Cập nhật'} biến động</Typography>
                    <IconButton
                        aria-label="close"
                        // className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">

                        {/* --- title --- */}
                        <div className="form-group">
                            <label className="text-dark fw-6">
                                Tiêu đề<span className="required"></span>
                            </label>
                            <TextField
                                name="title"
                                error={
                                    errors.title &&
                                    (errors.title.type === "required" ||
                                        errors.title.type === "maxLength")
                                }
                                fullWidth
                                type="text"
                                className="form-control"
                                //   inputProps={{ maxLength: 200 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                inputProps={{ maxLength: 150 }}
                                onChange={(e) =>
                                    setValue("title", e.target.value)
                                }
                            />
                            {errors.title && errors.title.type === "required" && (
                                <span className="error">Trường này là bắt buộc</span>
                            )}
                            {errors.title && errors.title.type === "maxLength" && (
                                <span className="error">Trường này không quá 150 ký tự</span>
                            )}
                        </div>

                        {/* --- description --- */}
                        {/* <div className="form-group">
                                <label className="text-dark fw-6">Tóm tắt<span className="required"></span></label>
                                <textarea
                                    name="description"
                                    rows="5"
                                    ref={register({ required: true, maxLength: 500 })}
                                    maxLength="500"
                                    className={
                                        "form-control" +
                                        (errors.description && errors.description.type === "required"
                                            ? " is-invalid"
                                            : "")
                                    }
                                ></textarea>
                                {errors.description && errors.description.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.description && errors.description.type === "maxLength" && (
                                    <span className="error">Trường này không quá 500 ký tự</span>
                                )}

                            </div> */}

                        {/* --- status addAddress --- */}
                        <div className="form-group">
                            <div className="row">
                                {userHandleModel && userHandleModel.length > 0 && (
                                    <div className="col-3">
                                        <label className="text-dark fw-6">
                                            Người xử lý<span className="required"></span>
                                        </label>

                                        <Autocomplete
                                            options={userHandleModel}
                                            getOptionLabel={(option) =>
                                                typeof option === "string" ? option : option.userName
                                            }
                                            value={userHandleSelected}
                                            onChange={(event, newValue) => {
                                                setUserHandleSelected(newValue);
                                                setValue("userHandleId", newValue.id);
                                                console.log('newValue', newValue)
                                            }}
                                            disableClearable={true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="userHandleId"
                                                    {...register('userHandleId', { required: true })}
                                                    error={
                                                        errors.userHandleId &&
                                                        errors.userHandleId.type === "required"
                                                    }
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        />
                                        {errors.userHandleId &&
                                            errors.userHandleId.type === "required" && (
                                                <span className="error">Trường này là bắt buộc</span>
                                            )}
                                    </div>
                                )}
                                <div className="col-9">
                                    <label className="text-dark fw-6">
                                        Địa chỉ<span className="required"></span>
                                    </label>
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-9 col-lg-9">
                                            <SuggestionAddress
                                                handleChoose={handleChangeAddress}
                                                addressSelected={addressSelected}
                                                setAddressSelected={setAddressSelected}
                                            />
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <button className="btn_rs" onClick={() => setOpenAddAddress(true)}>+ Thêm địa chỉ</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-12">
                                    <label className="text-dark fw-6">
                                        Ảnh avatar
                                    </label>
                                    <DropzoneArea
                                        filesLimit={1}
                                        acceptedFiles={['image/*']}
                                        maxFileSize={500000000}
                                        onChange={(files) => setGallery(files)}
                                        showFileNames={true}
                                    />
                                </div>
                            </div>
                        </div>



                        <DialogActions className="border-top">
                            <Button
                                type="submit"
                                onClick={onClose}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                            >
                                Lưu
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
                {openAddAddress && (
                    <FormAddAddress
                        isOpen={openAddAddress}
                        onClose={() => setOpenAddAddress(false)}
                        setAddressSelected={setAddressSelected}
                    />
                )}
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Receive);