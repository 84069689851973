import React from "react";
import "./layout-remote-sensing.scss";
import Header from "./header/header.view.jsx";
import Footer from "./footer/footer.view.jsx";
import LoadingScreen from '../../../components/loading-with-queue/loading-with-queue';

export default class LayoutRemoteSensing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="wrapper">
                <LoadingScreen />
                <div id="content-wrapper" className="d-flex flex-column">
                    <Header />
                    <div id="content1">
                        <div className="">{this.props.children}</div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
