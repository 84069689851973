import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import dateformat from "dateformat";
import { makeStyles } from "@material-ui/core/styles";
import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
import * as appActions from "../../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import * as communeAction from "../../../../redux/store/commune-management/commune.store";
import { Pagination } from "@material-ui/lab";
import { Select, MenuItem, Tooltip, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Autocomplete from "@material-ui/lab/Autocomplete";
import exportExcelIcon from "../../../../assets/icon/excel.svg";
import { saveAs } from "file-saver";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: window.outerHeight - 365,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    appBar: {
        position: "relative",
        backgroundColor: "#00923F",
    },
    title: {
        marginLeft: theme.spacing(0),
        flex: 1,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const listTableCell = [
    { id: 'title', hideSortIcon: false, isSort: true, label: 'Tên biến động', width: '14%' },
    { id: 'content', hideSortIcon: false, isSort: true, label: 'Nội dung', width: '30%' },
    { id: 'address', hideSortIcon: true, isSort: false, label: 'Địa chỉ', width: '25%' },
    { id: 'commune', hideSortIcon: true, isSort: false, label: 'Xã / phường', width: '25%' },
    { id: 'userhandler', hideSortIcon: false, isSort: true, label: 'Người xử lý', width: '30%' },
    { id: 'reflectionStatusId', hideSortIcon: false, isSort: true, label: 'Trạng thái', width: '30%' },
    // { id: 'createdBy', hideSortIcon: false, isSort: true, label: 'Người tạo', width: '14%' },
    { id: 'createdDate', hideSortIcon: false, isSort: true, label: 'Ngày biến động', width: '14%' },
];

const TableItem = props => {
    const { showLoading, index, title, isGeneral, isByCommune, isByDate, isByStatus } = props;
    const date = new Date();
    const today = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        0,
        0
    );
    const classes = useStyles();
    const defaultStatusValue = 0;
    const [data, setData] = useState([]);
    const [communeModels, setCommuneModels] = useState([]);
    const [curCommune, setCurCommune] = useState({ id: 0, name: 'Chọn xã - phường' });
    const [statusId, setStatusId] = useState();
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [validationError, setValidationError] = useState('');
    const [pagination, setPagination] = useState({});
    const [searchValue, setSearchValue] = useState({
        pageIndex: 1,
        pageSize: 10,
    })
    const GetList = (params) => {
        showLoading(true);
        rsAction.GetListAll(params).then(res => {
            if (res && res.content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = res.content;
                setData(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }

    const GetCommuneByDistrictId = (id) => {
        showLoading(true);
        communeAction
            .GetLookupCommune(id)
            .then((res) => {
                if (res && res.content) {
                    setCommuneModels(res.content);
                    showLoading(false);
                }
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };

    const handleChangePage = (event, value) => {
        const newSearchValue = { ...pagination, pageIndex: value };
        setSearchValue(newSearchValue);
        GetList(newSearchValue);
    }

    const handleChangeRowsPerPage = (e) => {
        setSearchValue({ ...searchValue, pageSize: e.target.value })
    }

    const exportAction = () => {
        let name = '';
        if(isGeneral) {
            name = 'Danh_sach_bien_dong_Export';
        } else if(isByCommune) {
            name=`Danh_sach_bien_dong_${curCommune.name}_Export`;
        } else if(isByDate) {
            name=`Danh_sach_bien_dong_(${dateformat(fromDate, "dd/mm/yyyy") +' - '+ dateformat(toDate, "dd/mm/yyyy")})_Export`;
        } else if(isByStatus) {
            name = `Danh_sach_bien_dong_${statusId === 2 ? 'chua_xu_ly': 'da_xu_ly'}_Export`;
        }
        showLoading(true);
        rsAction.ExportFile(searchValue)
            .then((result) => {
                const blob = new Blob([result], {
                    type: "application/*",
                });
                saveAs(
                    blob,
                    `${name}_(${moment(
                        new Date()
                    ).format("YYYY-MM-DD HH:mm:ss")}).xlsx`
                );
                showLoading(false);
            })
            .catch((error) => {
                showLoading(false);
            });
    }

    const handleChangeStatus = (value) => {
        const status = parseInt(value);
        setStatusId(status);
        setSearchValue({ ...searchValue, statusId: status });
    }

    const handleFromDateChange = (date) => {
        setFromDate(date)
        if (isDateRangeValid(date, toDate)) {
            setSearchValue({ ...searchValue, fromDate: dateformat(date, "dd-mm-yyyy") })
        }
    };

    const handleToDateChange = (date) => {
        setToDate(date)
        if (isDateRangeValid(fromDate, date)) {
            setSearchValue({ ...searchValue, toDate: dateformat(date, "dd-mm-yyyy") })
        }
    };

    const isDateRangeValid = (startDate, endDate) => {
        if (startDate && endDate && startDate > endDate) {
            setValidationError('Ngày bắt đầu phải nhỏ hơn hoặc bằng ngày kết thúc');
            return false
        } else {
            setValidationError('');
            return true
        }
    }

    const handleChangeCommune = (data) => {
        console.log(data)
        setCurCommune(data);
        setSearchValue({ ...searchValue, communeId: data.id })
    }
    useEffect(() => {
        if (
            isGeneral ||
            (isByCommune && searchValue.communeId) ||
            (isByDate && searchValue.fromDate && searchValue.toDate) ||
            (isByStatus && (searchValue.statusId || searchValue.statusId === 0))
        ) {
            GetList(searchValue)
        }
        if (isByCommune && searchValue.communeId) {
            GetList(searchValue)
        }
    }, [searchValue])

    useEffect(() => {
        if (isByCommune) {
            GetCommuneByDistrictId(39)
        }
    }, [])



    return (
        <div>
            <div class="table_header" >
                <div className="table_header_right">
                    <div className="table_header_title"
                        data-toggle="collapse"
                        href={`#collapse_${index}`}
                        role="button" aria-expanded="true"
                        aria-controls={`collapse_${index}`}
                    >
                        {title}
                    </div>
                    {isByCommune && communeModels && communeModels.length > 0 && (
                        <div className="table_header_filter_commune">
                            <Autocomplete
                                options={communeModels}
                                getOptionLabel={(option) =>
                                    typeof option === "string" ? option : option.name
                                }
                                value={curCommune}
                                onChange={(event, newValue) => {
                                    handleChangeCommune(newValue);
                                }}
                                disableClearable={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="communeId"
                                        variant="standard"
                                        size="small"
                                    />
                                )}
                            />

                        </div>
                    )}
                    {isByDate && (
                        <div className="table_header_filter_date">
                            <div className="filter_date">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        label="Từ ngày"
                                        id="fromDate"
                                        name="fromDate"
                                        onChange={(date) => date && handleFromDateChange(date)}
                                        value={fromDate}
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        error={Boolean(validationError)}
                                        helperText={validationError}
                                        showTodayButton={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                fullWidth
                                                margin="normal"
                                                required
                                                InputProps={{
                                                    endAdornment: <DateRangeIcon />,
                                                }}
                                            />
                                        )}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            {searchValue.fromDate && (
                                <div className="filter_date">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            label="Đến ngày"
                                            id="toDate"
                                            name="toDate"
                                            onChange={(date) => date && handleToDateChange(date)}
                                            value={toDate}
                                            format="dd/MM/yyyy"
                                            fullWidth
                                            error={Boolean(validationError)}
                                            helperText={validationError}
                                            showTodayButton={true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    fullWidth
                                                    margin="normal"
                                                    required
                                                    InputProps={{
                                                        endAdornment: <DateRangeIcon />,
                                                    }}
                                                />
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            )}
                        </div>
                    )}
                    {isByStatus && (
                        <div className="table_header_filter_status">
                            <select
                                class="form-select"
                                aria-label="Default select example"
                                onChange={e => handleChangeStatus(e.target.value)}
                                defaultValue={defaultStatusValue}
                            >
                                <option selected value={0}>---Trạng thái---</option>
                                <option value={3}>Đã xử lý</option>
                                <option value={2}>Đang xử lý</option>
                            </select>
                        </div>
                    )}
                </div>
                {pagination.totalItemCount && (
                    <div className="table_header_right">
                        <div className="count">
                            Tổng số biến động: {pagination.totalItemCount}
                        </div>
                        <div className="">
                            <Tooltip title="Export">
                                <img
                                    src={exportExcelIcon}
                                    alt="Export"
                                    className="cursor-pointer"
                                    onClick={exportAction}
                                    style={{
                                        width: 26,
                                        height: 26,
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
            <div class={`collapse show`} id={`collapse_${index}`}>
                {data && data.length > 0 && (
                    <div class="card card-body">
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <TableContainer component={Paper}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {listTableCell.map(item => (
                                                    <TableCell
                                                        key={item.id}
                                                        className={
                                                            "pt-3 pb-3 text-nowrap " +
                                                            (item.id === "title" ? "MuiTableCell-freeze header_title" : "")
                                                        }
                                                    // width={(item.id === "title" ? "25%": "")}
                                                    >
                                                        {item.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data && data.length > 0 && data.map((item) => {
                                                if (!item.isDelete)
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={item.id}>
                                                            {/* <TableCell>
                                                        <img className="img_avatar" src={apiConfig.api + item.filePath} alt="avatar" />
                                                    </TableCell> */}
                                                            <TableCell className="MuiTableCell-freeze">{item.title}</TableCell>
                                                            <TableCell>{item.content}</TableCell>
                                                            <TableCell>{`${item.fullAddressName}, ${item.streetName}`}</TableCell>
                                                            <TableCell>{item.communeName}</TableCell>
                                                            <TableCell>{item?.userHandlerName}</TableCell>
                                                            <TableCell>{item.reflectionStatusName}</TableCell>
                                                            {/* <TableCell>{item.createdBy}</TableCell> */}
                                                            <TableCell>{dateformat(item.createdDate, "dd-mm-yyyy")}</TableCell>
                                                        </TableRow>
                                                    )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            {pagination.totalItemCount > 1 && (
                                <div className="pagination_footer">
                                    <div className='select-page'>
                                        <div>Số bản ghi mỗi trang :</div>
                                        <div className='rowPerPage'>
                                            <Select value={pagination.pageSize} onChange={handleChangeRowsPerPage}>
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>
                                                <MenuItem value={30}>30</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    {pagination.pageCount > 1 && (
                                        <Pagination
                                            count={pagination.pageCount}
                                            page={pagination.pageIndex}
                                            variant="outlined"
                                            shape="rounded"
                                            showFirstButton
                                            showLastButton
                                            color="primary"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TableItem);