import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const CreateConstructionInvestment = (body ,openCreateByShapeFile) => {
  if ( !openCreateByShapeFile ) {
    return service.post(ApiUrl.CreateConstructionInvestment, body).then(res => { 
      return res 
    }).catch(err => { throw err })
  } else {
    return service.post(ApiUrl.CreateConstructionInvestmentByShapeFile, body).then(res => { 
      return res 
    }).catch(err => { throw err })
  }
    
}

export const UpdateConstructionInvestment = (body) => {
    return service.post(ApiUrl.UpdateConstructionInvestment, body).then(res => { 
        return res 
    }).catch(err => { throw err })
}

export const DeleteConstructionInvestment = (id) => {
    return service.post(ApiUrl.DeleteConstructionInvestment + id).then(res => { 
        return res 
    }).catch(err => { throw err })
}

export const GetLookupStatus = () => {
  return service.get(ApiUrl.GetLookupStatusConstructionInvestment).then(res => { 
      return res 
  }).catch(err => { throw err })
}

export const GetLookupType = () => {
  return service.get(ApiUrl.GetLookupTypeConstructionInvestment).then(res => { 
      return res 
  }).catch(err => { throw err })
}

export const GetListAll = async (
    pageIndex = 1,
    // pageSize = config.Configs.DefaultPageSize,
    pageSize= 10,
    sort = "",
    keyword = "",
    place,
    scale,
    typeId,
  ) => {
    const params = new URLSearchParams();
    params.append("PageIndex", pageIndex);
    params.append("PageSize", pageSize);
    sort && params.append("Sorting", sort);
    keyword && params.append("Name", keyword);
    place && params.append("Place", place);
    scale && params.append("Scale", scale);
    typeId && params.append("TypeId", typeId);
    try {
      const res = await service.get(ApiUrl.GetListConstructionInvestment, params);
      return res;
    } catch (err) {
      throw err;
    }
  };
