import React, { useState } from "react";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { APIUrlDefault, NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as placeAction from "../../../redux/store/place/place.store"
//--- Action
import * as landTypeAction from "../../../redux/store/land-type/land-type.store"
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileManagement from "../../../components/file_management/file_management";
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddLandTypeManagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListLandType,
    rowsPerPage,
    showLoading,
    placeKindModels,
    placeTypeModels
  } = props;
  const [placeKindTemp, setPlaceKindTemp]= useState(placeKindModels);
  const [placeKindId, setPlaceKindId] = useState();
  const [placeTypeId, setPlaceTypeId] = useState();
  const [isImageShow, setImageShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [isGalleryShow, setGalleryShow] = useState(false);
  const [gallerySelected, setGallerySelected] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [galleryTemp, setGalleryTemp] = useState([]);

  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    const landTypeModel = { ...data,
      placeKindId: placeKindId,
      placeTypeId: placeTypeId,
      galaryImages: [] }

    files &&
    files.length > 0 &&
    files.forEach((file) => {
      if(file && file.fileId) {
        landTypeModel['DocumentUploadId'] = file.fileId;
      }
    });

    gallery &&
    gallery.length > 0 &&
    gallery.forEach((file) => {
      if(file && file.fileId) {
        landTypeModel.galaryImages.push(file.fileId);
      }
    });

    showLoading(true);

    placeAction.Create(landTypeModel).then((result) => {
      if (result && result.content && result.content.status === true) {
        GetListLandType(1, rowsPerPage);
        showLoading(false)
        onSuccess();
        ShowNotification(
          viVN.Success.AddLandType,
          NotificationMessageType.Success
        )
      }
    }).catch((err) => {
      showLoading(false)
      onSuccess();
      ShowNotification(
        viVN.Errors[err.errorType],
        NotificationMessageType.Error
      )
    })
  };

  const handleOnchangePlaceKind = (event, newValue) => {
    if (newValue) {
      setPlaceKindId(newValue.id);

    }
  };
  const handleOnchangePlaceType = (event, newValue) => {
    if (newValue) {
      setPlaceTypeId(newValue.id);
      let temp = placeKindModels.filter(type => type.placeTypeId == newValue.id);
      setPlaceKindTemp(temp)

    }
  };

  const onOpenSelectFile = () => {
    setImageShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setImageShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setImageShow(false);
  };

  const onCloseSelectGallery = () => {
    setGalleryShow(false);
    setGallery(galleryTemp);
  };

  const onSaveSelectGallery = () => {
    setGalleryShow(false);
    setGallerySelected([...gallerySelected, ...gallery])
  };

  const onOpenSelectGallery = () => {
    setGalleryShow(true);
    setGalleryTemp(gallery);
  };

  console.log('gallerygallerygallerygallery',gallery);
  console.log("filesfilesfilesfilesfilesfiles",files);

  const testSetFiles = (data) => {
    console.log('testSetFiles');
    setFiles(data)
  }
  const testSetGalaries = (data) => {
    console.log('testSetGalaries');
    setGallery(data)
  }
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm địa điểm</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Tên địa điểm<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="name"
                    className="w-100"
                    inputRef={register({ required: true })}
                    error={errors.name && errors.name.type === "required"}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">Địa chỉ<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="address"
                    className="w-100"
                    inputRef={register({
                      required: true,
                      // pattern: /^([0-9]{6})$/g
                    })}
                    error={errors.code && errors.code.type === "required"}
                  />
                  {errors.code && errors.code.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>


            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Lat<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="lat"
                    className="w-100"
                    inputRef={register({ required: true })}
                    error={errors.lat && errors.lat.type === "required"}
                  />
                  {errors.lat && errors.lat.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}

                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Long<span className="required"></span></label>
                  <TextField
                    type="text"
                    name="long"
                    className="w-100"
                    inputRef={register({ required: true})}
                    error={errors.long && (errors.long.type === "required" || errors.long.type === "pattern")}
                  />
                  {errors.long && errors.long.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  {
                    placeTypeModels && placeTypeModels.length > 0 && (
                      <Autocomplete
                        id="placeType"
                        options={placeTypeModels}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        onChange={(event, newValue) =>
                          handleOnchangePlaceType(event, newValue)
                        }
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Chọn loại địa điểm (*)"
                            name={`placeTypeId`}
                            inputRef={register({ required: true })}
                            size="small"
                            variant="outlined"
                            error={
                              errors[`placeTypeId`] &&
                              errors[`placeTypeId`].type === "required"
                            }
                          />
                        )}
                      />
                    )
                  }
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  {placeKindTemp && placeKindTemp.length > 0 && (
                    <Autocomplete
                      id="placeKindId"
                      options={placeKindTemp}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      onChange={(event, newValue) =>
                        handleOnchangePlaceKind(event, newValue)
                      }
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Chọn chi tiết loại địa điểm (*)"
                          name={`placeKindId`}
                          inputRef={register({ required: true })}
                          size="small"
                          variant="outlined"
                          error={
                            errors[`placeKindId`] &&
                            errors[`placeKindId`].type === "required"
                          }
                        />
                      )}
                    />
                    )
                  }
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="text-dark">
                Ảnh<span className="required"></span>
              </label>
              {!isImageShow &&
                files &&
                files.length > 0 &&
                files.map((item) => (
                  <div key={item.fileName} style={{ width: "150px" }}>
                    <img
                      src={APIUrlDefault + item.filePreview}
                      alt={item.fileName}
                      title={item.fileName}
                      className="img-fluid mb-2"
                      style={{
                        width: "auto",
                        height: "auto",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </div>
                ))}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onOpenSelectFile}
                >
                  Chọn file
                </Button>
                <TextField
                  inputRef={register({ required: true })}
                  type="hidden"
                  name="image"
                  value={
                    (files && files.length > 0 && files[0].fileName) || ""
                  }
                />
                {errors.image && errors.image.type === "required" && (
                  <p className="error">Trường này là bắt buộc</p>
                )}
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <label className="text-dark">Thư viện ảnh</label> </div>
                <div className="col-12">
                  <div className="list__img d-flex flex-wrap mt-1">
                      {!isGalleryShow &&
                          gallerySelected &&
                          gallerySelected.length > 0 &&
                          gallerySelected.map((item) => (
                              <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                                  <img
                                      src={APIUrlDefault + item.filePreview}
                                      alt={item.fileName}
                                      title={item.fileName}
                                      className="img-fluid mb-2"
                                      style={{
                                          width: "auto",
                                          height: "auto",
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                      }}
                                  />
                                  <p className="file_name">{item.fileName.length > 15 ? (item.fileName.substring(0, 15) + '...') : item.fileName}</p>
                                  <p
                                      className="close_x"
                                      onClick={() =>
                                          setGallerySelected(gallerySelected.filter(i => i !== item))
                                      }
                                  >
                                      &#10005;
                                  </p>
                              </div>
                          ))}
                  </div>
                </div>
                <div className="col-12">
                  <div>
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={onOpenSelectGallery}
                      >
                          Chọn file
                      </Button>
                  </div>
                </div>
             </div>
            </div>
          
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {isImageShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isImageShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file - Chọn 1 ảnh</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={testSetFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isGalleryShow && (
        <Dialog
            onClose={onCloseSelectGallery}
            open={isGalleryShow}
            fullWidth={true}
            maxWidth="md"
            className="dialog-preview-form"
        >
            <DialogTitle disableTypography>
                <Typography variant="h6">Quản lý file - Chọn nhiều ảnh</Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onCloseSelectGallery}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <FileManagement
                  multiple={true}
                  files={gallery}
                  setFiles={testSetGalaries}
                  acceptedFiles={["png", "jpg"]}
              />
            </DialogContent>

            <DialogActions>
              <Button
                  type="button"
                  onClick={onCloseSelectGallery}
                  variant="contained"
                  startIcon={<CloseIcon />}
              >
                  Hủy
              </Button>
              {gallery && gallery.length > 0 && (
                  <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={onSaveSelectGallery}
                  >
                      Lưu
                  </Button>
              )}
            </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
