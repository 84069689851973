import React, { useEffect, useState } from "react";

import "./show-notifications.scss";
import { getMessagingToken, onMessageListener } from "../../firebase.ts";
import history from "../../common/history";

const ShowNotifications = (props) => {
    const {action} = props;
    const [data, setData] = useState()
    const [active, setActive] = useState(false);

    useEffect(() => {
        // getMessagingToken();
    }, [])
    useEffect(() => {
        onMessageListener()
            .then(data => {
                console.log(data)
                action && action();
                setData(data);
                setActive(true);
                const deActive = setTimeout(() => setActive(false), 5000);
            })
    })

    const functionHandleClick = () => {
        setActive(false);
        history.push('/danh-sach-cho');
    }

    return (
        <>
            {data && (
                <div
                    className={`show-notifications ${active ? 'active' : ''}`}
                >
                    <div className="title">
                        {data.notification.title}
                    </div>
                    <div className="content" onClick={() => functionHandleClick()}>
                        {data.notification.body}!
                    </div>
                    {active && (
                        <audio autoPlay>
                            <source src={require("./notification-sound.mp3")} type="audio/mpeg" />
                        </audio>
                    )}
                </div>
            )}
        </>
    )
}

export default ShowNotifications;