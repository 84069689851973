import React from "react";
import "./footer.scss";

function RemoteSensing(props) {
    return (
        <div className="footer_container">
            <div className="container mw_1500">
                <div className="footer_content">
                    <div className="footer_left">
                        <p>Ứng dụng ảnh viễn thám và hệ thống thông tin địa lý trong quản lý trận tự xây dựng</p>
                    </div>
                    <div className="footer_right">
                        <p>powered by CGIS TM 2009</p>
                        {/* <p>Provided by CTECH</p> */}
                        <p>Made by ThuyenDev</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemoteSensing;