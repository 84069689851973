import React, { useEffect, useState } from "react";
import * as rsAction from "../../../../redux/store/remote-sensing/remote-sensing.store";
import * as appActions from "../../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import dateformat from "dateformat";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Button } from "@material-ui/core";

import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import CollectionsIcon from '@material-ui/icons/Collections';
import MapIcon from '@material-ui/icons/Map';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PaginationView from "../../components/pagination/pagination.view";

// import "./history-remote-sensing.scss";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";

import Receive from "./receive/receive.view";
import DeleteDialog from "../../components/form/delete-dialog.view";
import GalleryImages from "../../components/gallery/gallery.view";
import AddShapeFileView from "../../components/form/add-shape-file.view";
import AddReflectView from "../../components/form/add-reflect.view";
import history from "../../../../common/history";
import MapView from "../../components/form/mapView.view";

import "./pending.scss";

function RemoteSensingPending(props) {
    const { showLoading } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const defaultStatusValue = queryParams.get("status") === 'false' ? 1 : queryParams.get("status") === 'true' ? 2 : 0;
    const [data, setData] = useState([]);
    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(1);
    const [orderBy, setOrderBy] = useState('modifiedDate');
    const [order, setOrder] = useState('desc');
    const [pagination, setPagination] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAddShape, setIsOpenAddShape] = useState(false);
    const [isOpenAddPending, setIsOpenAddPending] = useState(false);
    const [pendingId, setPendingId] = useState();
    const [pendingSelected, setPendingSelected] = useState();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenGallery, setIsOpenGallery] = useState(false);
    const [gallerySelected, setGallerySelected] = useState([]);
    const [isOpenMapView, setIsOpenMapView] = useState(false);

    const GetList = (params) => {
        showLoading(true);
        rsAction.GetListPending(params).then(res => {
            if (res && res.content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = res.content;
                setData(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            }
            showLoading(false);
        }).catch(err => showLoading(false))
    }

    const handleOpenDialogDelete = (id) => {
        setPendingId(id);
        setIsOpenDelete(true);
    }

    const handleDelete = () => {
        showLoading(true);
        rsAction.DeletePending(pendingId).then(res => {
            if (res) {
                ShowNotification("Xóa thành công", NotificationMessageType.Success);
                setIsOpenDelete(false);
                reloadData();
            }
            showLoading(false);
        }).catch(err => {
            err &&
                err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            showLoading(false);
        })
    }

    const handleOpenReceive = (id) => {
        setIsOpen(true);
        setPendingId(id);
    }

    const handleOpenGallery = (gallery) => {
        setGallerySelected(gallery);
        setIsOpenGallery(true);
    }

    const handleOpenMapView = (pending) => {
        setPendingSelected(pending);
        setIsOpenMapView(true);
    }

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const onRequestSort = (event, property) => {
        console.log(event, property)
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sorting = property + ' ' + sort;
        GetList({
            pageSize: queryParams.get("pageSize") || pageSize,
            pageIndex: queryParams.get("pageIndex") || 1,
            status: queryParams.get("status"),
            sorting: sorting,
        })
    }

    const listTableCell = [
        { id: 'eventDate', hideSortIcon: false, isSort: true, label: 'Ngày Biến động', width: '15%' },
        // { id: 'content', hideSortIcon: false, isSort: true, label: 'Nội dung', width: '40%' },
        { id: 'status', hideSortIcon: false, isSort: true, label: 'Trạng thái', width: '15%' },
        { id: 'gallery', hideSortIcon: true, isSort: false, label: 'Bản đồ', width: '15%' },
        { id: 'action', hideSortIcon: false, isSort: true, label: '', width: '15%' },
    ]

    const reloadData = () => {
        let params = {
            pageSize: queryParams.get("pageSize") || pageSize,
            pageIndex: queryParams.get("pageIndex") || 1,
            status: queryParams.get("status")
        }
        GetList(params);
    }

    const refreshData = () => {
        let params = {
            pageSize: pageSize,
            pageIndex: 1,
        }
        GetList(params);
    }

    useEffect(() => {
        let params = {
            pageSize: queryParams.get("pageSize") || pageSize,
            pageIndex: queryParams.get("pageIndex") || 1,
            status: queryParams.get("status")
        }
        GetList(params);
    }, [location.search])

    const handleChangeStatus = (value) => {
        const status = parseInt(value);
        switch (status) {
            case 0:
                queryParams.delete('status')
                break;
            case 1:
                queryParams.set('status', 'false')
                break;
            case 2:
                queryParams.set('status', 'true')
                break;
            default:
                queryParams.delete('status')
        }
        history.push({
            search: queryParams.toString(),
        })
    }

    return (
        <div>
            <div className="page_header">
                <div className="page_title">
                    <h3>Danh sách chờ</h3>
                </div>
            </div>

            <div className="container mw_1200 history_rs">
                <div className="page_header2 pending">
                    <div className="page_filter">
                        <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={e => handleChangeStatus(e.target.value)}
                            defaultValue={defaultStatusValue}
                        >
                            <option selected value={0}>---Trạng thái---</option>
                            <option value={1}>Chờ tiếp nhận</option>
                            <option value={2}>Đã tiếp nhận</option>
                        </select>
                    </div>
                    <div className="header_btton">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setIsOpenAddShape(true)}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Thêm Shape file
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setIsOpenAddPending(true)}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Thêm biến động
                        </Button>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {listTableCell.map(item => (
                                    <TableCell
                                        key={item.id}
                                        className='text_bold'
                                        width={item.width}
                                    >
                                        <TableSortLabel
                                            active={orderBy === item.id}
                                            direction={orderBy === item.id ? order : 'asc'}
                                            onClick={item.isSort ? createSortHandler(item.id) : null}
                                            hideSortIcon={item.hideSortIcon ? true : false}>
                                            {item.label}
                                            {/* {orderBy === item.id ? (
                                                            <span>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null} */}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.length > 0 && data.map((item) => {
                                if (!item.isDelete)
                                    return (
                                        <TableRow hover tabIndex={-1} key={item.id}>
                                            <TableCell>{dateformat(item.eventDate, "dd-mm-yyyy")}</TableCell>
                                            {/* <TableCell>{item.content}</TableCell> */}
                                            <TableCell>{item.status ? 'Đã tiếp nhận' : 'Chờ tiếp nhận'}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Xem trân bản đồ">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => handleOpenMapView(item)}
                                                    >
                                                        <MapIcon className="text-primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Phê duyệt">
                                                    <IconButton
                                                        disabled={item.status}
                                                        aria-label="edit"
                                                        onClick={() => handleOpenReceive(item.id)}
                                                    >
                                                        <PublishIcon className={item.status ? 'text-secondary' : "text-primary"} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Xóa">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => handleOpenDialogDelete(item.id)}
                                                    >
                                                        <DeleteIcon className="text-danger" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="float-end">
                    <PaginationView
                        showFirstLastButton
                        totalRecords={pagination.totalItemCount}
                        pageLimit={pagination.pageSize}
                        pageCount={pagination.pageCount}
                        currentPage={pagination.pageIndex}
                    />
                </div>
                {isOpen && (
                    <Receive
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                        pendingId={pendingId}
                        reloadData={reloadData}
                    />
                )}
                {isOpenAddShape && (
                    <AddShapeFileView
                        isOpen={isOpenAddShape}
                        onClose={() => setIsOpenAddShape(false)}
                    />
                )}
                {isOpenAddPending && (
                    <AddReflectView
                        isOpen={isOpenAddPending}
                        onClose={() => setIsOpenAddPending(false)}
                        reloadData={refreshData}
                    />
                )}
                {isOpenDelete && (
                    <DeleteDialog
                        isOpen={isOpenDelete}
                        onClose={() => setIsOpenDelete(false)}
                        onSuccess={handleDelete}
                        header={"Xác nhận xóa"}
                        content={"Bạn có chắc chắn muốn xóa?"}
                    />
                )}
                {isOpenGallery && (
                    <GalleryImages
                        isOpen={isOpenGallery}
                        onClose={() => setIsOpenGallery(false)}
                        data={gallerySelected}
                        initIndex={1}
                    />
                )}
                {isOpenMapView && (
                    <div class="pending_map_view">
                        <MapView
                            isOpen={isOpenMapView}
                            onClose={() => setIsOpenMapView(false)}
                            data={pendingSelected}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RemoteSensingPending);