import React, { useEffect } from "react";
import {
    DomainAdminSide,
    TokenKey,
    getUserInfo,
    removeCookies,
    APIUrlDefault,
    setCookies,
} from "../../utils/configuration";
import { useLocation } from 'react-router-dom';


const LoginFromMobile = (props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const tokenMobile = localStorage.getItem("setAuthToken") || null;
    useEffect(() => {
        if (queryParams.get('setAuthToken')) {
            setCookies(TokenKey.token, queryParams.get('setAuthToken'));
            window.location.replace('/ban-do-vien-tham');
        }
    },[location.search])
    return (
        <div>
            login from mobile
        </div>
    )
}

export default LoginFromMobile;