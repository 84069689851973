import React, { useEffect } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useLocation } from 'react-router-dom';
import history from "../../../../common/history";

import "./pagination.scss"

const PaginationView = props => {
    const {
        pageCount,
        totalRecords = 10,
        pageLimit = 10,
        currentPage = 1,
        showFirstLastButton
    } = props || {};

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {console.log('pageCount', pageCount)},[pageCount])
    const handleChange = (event, value) => {
        queryParams.set('pageIndex', value) 
        history.push({
            search: queryParams.toString(),
        })
      };

    useEffect(() => {
        console.log('pageIndex',queryParams)
    },[])

    return (
        <div className="pagination_">
            <Pagination
                count={pageCount}
                page={currentPage}
                variant="outlined"
                shape="rounded"
                showFirstButton={showFirstLastButton}
                showLastButton={showFirstLastButton}
                color="primary"
                onChange={handleChange}
            />
        </div>
    )
}

export default PaginationView;