import React, { useState } from "react";
import TuneIcon from "@material-ui/icons/Tune";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import HistoryIcon from "@material-ui/icons/History";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RenderImg from "../components/render-img.view";

function RenderBy ({list, listImg, type}) {
    return (
        <div key={type} className="region_block">
            {list.map((item, index) => (
                <div key={index} className="region_item">
                    <div className="region_header">
                        <p>{item.name}</p>
                        <div className="region_header_btn">
                            <a
                                className="button_collapse collapsed"
                                data-toggle="collapse"
                                href={`#collapse-${index}`}
                                role="button"
                                aria-expanded="false"
                                aria-controls={`collapse-${index}`}
                            >
                                <IconButton className="arrow_left" aria-label="Thông báo" title="Mở rộng">
                                    <ArrowLeftIcon fontSize="medium" />
                                </IconButton>
                                <IconButton className="arrow_down" aria-label="Thông báo" title="Thu gọn">
                                    <ArrowDropDownIcon fontSize="medium" />
                                </IconButton>
                            </a>
                            <IconButton aria-label="Thông báo" title="Tùy chỉnh">
                                <TuneIcon fontSize="medium" className="" />
                            </IconButton>
                        </div>
                    </div>
                    <div className="collapse" id={`collapse-${index}`}>
                        <div className="card card-body">
                            <div className="list_img">
                                {listImg.map((item, index) => (
                                    <RenderImg 
                                        data={item}
                                        key={index}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default RenderBy;