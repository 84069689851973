import React, { useState, useEffect } from "react";
import TuneIcon from "@material-ui/icons/Tune";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import HistoryIcon from "@material-ui/icons/History";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { listImg, listByDate, listByRegion } from "../components/model"
import * as documentAction from "../../../redux/store/remote-sensing/document.store";
import * as appActions from "../../../core/app.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as apiConfig from "../../../api/api-config";

import RenderBy from './render-by.view'
import GalaryImages from "../components/gallery/gallery.view";
import SimpleDialog from "../components/simple-dialog/simple-dialog.view";
import PaginationView from "../components/pagination/pagination.view";
import { useLocation } from 'react-router-dom';

import "./photo-gallery-remote-sensing.scss";

function PhotoGalleryRemoteSensing(props) {
    const { showLoading } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [currentTab, setCurrentTab] = useState('synthetic')
    const pageSize = 18;
    const [pageIndex, setPageIndex] = useState(1);
    const [documentModel, setDocumentModel] = useState([]);
    const [isOpenGallary, setIsOpenGallary] = useState(false);
    const [index, setIndex] = useState(0);
    const [documentSelected, setDocumentSelected] = useState();
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [pagination, setPagination] = useState({});

    const listTabs = [
        {
            name: 'synthetic',
            title: 'Kho tổng hợp',
            active: currentTab == 'synthetic',
        },
        // {
        //     name: 'region',
        //     title: 'Theo địa phận',
        //     active: currentTab == 'region',
        // },
        // {
        //     name: 'time',
        //     title: 'Theo thời gian',
        //     active: currentTab == 'time',
        // },
    ];

    const handleChangeTab = (tabName) => {
        setCurrentTab(tabName)
    }

    useEffect(() => {
        let params = {
            pageSize: queryParams.get('pageSize') || 18,
            pageIndex: queryParams.get('pageIndex') || 1,
            // communeId: queryParams.get('communeId') || null,
            // streetName: queryParams.get('streetName') || null,
        }
        GetListAllDocument(params);
    }, [location.search])

    const GetListAllDocument = (params) => {
        showLoading(true);
        documentAction.GetListAllDocument(params).then(res => {
            if (res && res.content && res.content.items) {
                setDocumentModel(res.content.items)
                showLoading(false);
            }
        }).catch(err => {
            showLoading(false);
        })
    }

    const HandleOpenGallery = (index) => {
        setIsOpenGallary(true);
        setIndex(index);
    }

    const handleOpenPopup = (item) => {
        setDocumentSelected(item);
        setIsOpenPopup(true)
    }

    const renderAll = (list) => {
        return (
            <div className="synthetic_block">
                <div className="card card-body">
                    <div className="list_img">
                        {documentModel && documentModel.length > 0 && documentModel.map((item, index) => (
                            <div key={index} className="img_item">
                                <img src={apiConfig.api + item.path}
                                    alt={item.title}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = require('../../../assets/images/no-image-found.png');
                                    }}
                                />
                                <div className="img_item_overlay">
                                    <ZoomOutMapIcon onClick={() => HandleOpenGallery(index)} className="" fontSize="large" />
                                    <HistoryIcon onClick={() => handleOpenPopup(item)} className="" fontSize="large" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pagination">
                    <PaginationView
                        showFirstLastButton
                        pageCount={pagination.pageCount}
                        totalRecords={pagination.totalItemCount}
                        pageLimit={pagination.pageSize}
                        currentPage={pagination.pageIndex}
                    />
                </div>
            </div>
        )
    }

    const renderContent = () => {
        switch (currentTab) {
            case 'synthetic':
                return renderAll();
            case 'region':
                return (
                    <RenderBy list={listByRegion} listImg={listImg} type='region' />
                );
            case 'time':
                return (
                    <RenderBy list={listByDate} listImg={listImg} type='time' />
                );
            default:
                return null;
        }
    }

    return (
        <div>
            <div className="page_header">
                <div className="page_title">
                    <h3>Kho ảnh hiện trạng</h3>
                </div>
            </div>
            <div className="container mw_1500 photo_gallery">
                <div className="tab_option">
                    {listTabs.map((item, index) => (
                        <div
                            key={index}
                            className={`tab_item ${item.active ? 'active' : ''}`}
                            onClick={!item.active ? () => handleChangeTab(item.name) : null}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
                <hr className="sidebar_" style={{ borderColor: 'rgba(0, 0, 0, 0.1)' }} />
                {renderContent()}
            </div>
            {isOpenGallary &&
                <GalaryImages
                    onClose={() => setIsOpenGallary(false)}
                    initIndex={index}
                    data={documentModel}
                />
            }
            {isOpenPopup &&
                <SimpleDialog
                    isOpen={isOpenPopup}
                    onClose={() => setIsOpenPopup(false)}
                    data={documentSelected}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGalleryRemoteSensing);